<template>
  <div id="app">
    <!-- <router-view></router-view> -->
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  provide() {
    //副组件通过provide 提供变量,子组件通过inject来注入
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      //刷新页面
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>

<style lang='scss'>
.el-popover {
  z-index: 20000 !important;
}
.el-loading-mask {
  z-index: 99999 !important;
}
body {
  margin: 0;
  padding: 0;
}
.el-table__fixed-right,
.el-table__fixed-left {
  height: 100% !important;
}

#app {
  background-color: #f2f2f2;
}

.flex_jcSB_aiC {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_aiC {
  display: flex;
  align-items: center;
}

// .el-input--suffix .el-input__inner {
//   padding-right: 10px;
// }

// 表格
// 表格头部按钮
.btnList1 .el-button {
  border: 1px solid #606266;
  padding: 5px !important;
  background-color: #fafafa;
  color: #333333;
  min-width: 64px;
}

.btnList {
  // padding: 8px;
  display: flex;
  align-items: center;
  background-color: #fff;
  // background-color: #f2f2f2;
  // margin: 8px 0;
  flex-wrap: wrap;
  .itemBtn {
    // height: 30px;
    // padding: 0 13px;
    // line-height: 30px;
    margin: 8px;
  }
  .dropdownBtn {
    background-color: #f0a261;
    color: #fff;
    border: none;
  }
  .el-button {
    padding: 8px;
  }

  .el-dropdown {
    margin: 8px;
  }
}

.btnColor {
  background-color: #f0a261;
  color: #fff;
}

.titles_dialog {
  font-size: 13px;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 8px;
}

// 拖拽
.daraStyle {
  height: 40px;
  line-height: 40px;
  border: 1px solid #d7d7d7;
  padding: 0 10px;
  border-bottom: none;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .rowChecks {
    min-width: 150px;
    flex: 1;
  }

  .daraNum {
    margin-right: 10px;
    min-width: 26px;
    width: 26px;
    height: 26px;
    background-color: #f0a261;
    color: #fff;
    text-align: center;
    line-height: 26px;
    border-radius: 50%;
  }

  .cloWidth {
    flex: 1;
    min-width: 150px;
    display: flex;
    align-items: center;
    .cloLabel {
      width: 32px;
      text-align: right;
      margin-right: 8px;
    }
    .cloInput {
      width: 80px;
      border: 1px solid #d7d7d7;
      line-height: 30px;
      height: 30px;
    }
  }
}

.daraStyle:hover {
  background-color: #f2f2f2;
}

.daraStyle:last-of-type {
  border-bottom: 1px solid #d7d7d7;
}

.btnList1 .el-button:hover {
  color: #606266 !important;
  border: 1px solid #ef7c1b;
}

//查询框
.queryCriteria {
  width: 100%;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  padding: 10px 10px 0;
  // margin-bottom: 10px;

  .item_left {
    width: 240px;
  }
  .item_right {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
  }
  //普通输入框类型的
  .queryItem {
    width: 222px;
    display: flex;
    margin-bottom: 10px;
    align-items: center;
    font-size: 13px;

    .queryLabel {
      width: 90px;
      text-align: right;
      margin-right: 10px;
    }

    .right_inpu {
      width: 120px;
      .queryInput {
        width: 100%;
        border: 1px solid #d7d7d7;
      }
    }
    // .right_inpu_textarea {
    //   width: 120px;
    //   .queryTextarea {
    //     width: 100%;
    //     border: 1px solid #d7d7d7;
    //   }
    // }
  }
  // 时间类型的
  .queryItemTime {
    .el-input--suffix .el-input__inner {
      padding-right: 20px;
    }
  }
  .timeBox {
    display: flex;
    align-items: center;
    margin: 0 10px 10px;
    .queryInput {
      width: 120px;
    }
    .el-range-editor.el-input__inner {
      padding: 0px 10px;
    }
  }
  //第一个运单号
  .queryItem_one {
    width: 240px;
    display: flex;
    margin-bottom: 10px;
    font-size: 13px;

    .queryLabel {
      width: 90px;
      text-align: right;
      margin-right: 10px;
    }
    .right_inpu_textarea {
      width: 120px;
      .queryTextarea {
        width: 100%;
        // border: 1px solid #d7d7d7;
      }
    }
  }
  .queryItembtn {
    width: 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    .queryBtn {
      height: 30px;
      padding: 0 13px;
      line-height: 30px;
    }
  }
}

.el-input__icon {
  line-height: 30px;
}

/*.el-input__inner {*/
/*min-height: 28px;*/
/*height: 28px;*/
/*border: 0;*/
/*line-height: 28px;*/
/*}*/

.daraStyle .el-input__inner {
  min-height: 28px;
  height: 28px;
  border: 0;
  line-height: 28px;
}

//element ui的样式
//  弹窗的
// 弹出层
.el-dialog {
  margin-top: 10vh !important;
  min-width: 600px;
}

.el-dialog--center {
  margin-top: 10vh !important;
  max-height: 84vh;
  min-height: 100px;
  // overflow-y: scroll;
}

.el-dialog--center .el-dialog__body {
  padding: 10px 15px;
}

.el-dialog--center .el-dialog__body {
  padding: 10px 15px;
  max-height: 66vh;
  overflow-y: scroll;
}

.el-dialog__footer {
  border-top: 1px solid #d7d7d7;
  padding: 10px;
}

// 表格后面多出的一块
.el-table td.gutter,
.el-table th.gutter {
  display: none;
}

//标题
.titles {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  display: flex;
  align-items: center;
  margin: 20px 13px;

  .lines {
    height: 18px;
    width: 3px;
    background-color: #333;
    margin-right: 10px;
  }
}

//合计背景颜色
.el-table__footer-wrapper tbody td,
.el-table__header-wrapper tbody td {
  background-color: #f2f2f2;
}

//合计序号背景颜色
.el-table__fixed-footer-wrapper tbody td {
  background-color: #f2f2f2;
}

// 表头字体颜色
.el-table {
  font-size: 13px;
}

// 表格文字居中
.el-table td,
.el-table th {
  text-align: center;
  padding: 4px;
}

.el-table__empty-block {
  width: 100% !important;
}

.el-pagination .el-select .el-input .el-input__inner {
  border: 1px solid #d7d7d7;
}

.el-table th {
  background-color: #f5f7fa;
}

.singleinp:hover {
  border: 1px solid #f0a261;
  box-shadow: 0px 0px 5px #f0a261;
}
.singleinp1 {
  height: 28px;
  // margin: 0 0.4%;
  margin-right: 6px;
  width: 38%;
  min-width: 214px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: 10px;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 120px;
  }
}
.singleinp {
  height: 28px;
  // margin: 0 0.4%;
  margin-right: 6px;
  width: 19%;
  min-width: 214px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: 10px;
  .el-date-editor.el-input,
  .el-date-editor.el-input__inner {
    width: 120px;
  }
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

//switch开关
.el-switch__label.is-active {
  color: #606266;
}

.el-switch__label {
  color: #606266;
}

//查询条件框样式(新版)
.HeadInputbox {
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
  background-color: #fff;
  overflow: hidden;
  .el-input__inner {
    min-height: 28px;
    height: 28px;
    border: 0;
    line-height: 28px;
  }

  .item_right {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .singleinp:hover {
      border: 1px solid #f0a261;
      box-shadow: 0px 0px 5px #f0a261;
    }
    .singleinp {
      height: 28px;
      // margin: 0 0.4%;
      margin-right: 6px;
      width: 18%;
      min-width: 214px;
      border: 1px solid #cccccc;
      border-radius: 5px;
      display: flex;
      align-items: center;
      font-size: 13px;
      margin-bottom: 10px;
      .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 120px;
      }
    }
    .singleinpleft {
      width: 140px;
      min-width: 82px;
      color: #606266;
      text-align: right;
      margin-right: 10px;
    }

    .singleinp_time {
      height: 28px;
      margin: 0 0.4%;
      border: 1px solid #cccccc;
      border-radius: 5px;
      width: 32%;
      min-width: 392px;
      margin-bottom: 10px;
      // 时间类型的
      .queryItemTime {
        .el-input--suffix .el-input__inner {
          padding-right: 20px;
        }
        .right_inpu {
          flex: 1;
          .el-date-editor.el-input,
          .el-date-editor.el-input__inner {
            width: 100%;
          }
          .queryInput {
            width: 100%;
            border: 1px solid #d7d7d7;
          }
        }
      }
      .timeBox {
        display: flex;
        align-items: center;
        margin: 0 10px 10px;
        .queryInput {
          width: 120px;
        }
        .el-range-editor.el-input__inner {
          padding: 0px 10px;
        }
      }

      .wtl_timeStyle {
        display: flex;
        align-items: center;
        height: 28px;
      }
    }

    .singleinpright {
      width: 100%;
      .input .el-input__inner {
        border: 0;
        min-height: 28px;
        height: 28px;
      }
      .input {
        width: 100%;
        height: 28px;
        // border: 1px solid #d7d7d7;
      }
      .el-input--suffix .el-input__inner {
        padding-right: 20px;
      }
    }
    .singleinpbtn {
      width: 19%;
      min-width: 214px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      /* .el-button {
                  padding: 7px;
                  background-color: #f0a261;
                  color: #fff;
                }
                .el-button:hover {
                  background-color: #f0a261;
                  color: #fff;
                  border: 0.5px solid #f0a261;
                } */

      .queryBtn {
        height: 30px;
        padding: 0 10px;
        line-height: 30px;
        margin-left: 20px;
      }
      .condition {
        color: #2c6ae1;
        font-size: 13px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
  .item_left {
    width: 200px;
    .singleinpbtn {
      justify-content: flex-end;
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      /* .el-button {
                  padding: 8px;
                  background-color: #f0a261;
                  color: #fff;
                }
                .el-button:hover {
                  background-color: #f0a261;
                  color: #fff;
                  border: 0.5px solid #f0a261;
                } */
      .queryBtn {
        height: 30px;
        padding: 0 10px;
        line-height: 30px;
        margin-left: 20px;
      }
      .condition {
        color: #2c6ae1;
        font-size: 13px;
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }
}

//按钮列表(新)
.btnList1 {
  // padding: 8px;
  width: 100%;
  margin: 8px auto;
  margin-top: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;
  // background-color: #f2f2f2;
  // margin: 8px 0;
  flex-wrap: wrap;

  .el-dropdown {
    margin-left: 10px;
    height: 25px;
  }
  .el-button:hover {
    color: #606266 !important;
    border: 1px solid #ef7c1b;
  }
  // 表格头部按钮
  .el-button {
    border: 1px solid #606266;
    padding: 5px !important;
    background-color: #fafafa;
    color: #333333;
  }

  .wtl_mainBtns {
    background-color: #fa6d25;
    color: #fff;

    border: none !important;
  }
  .wtl_mainBtns:hover {
    color: #fff !important;
  }
  .wtl_mainBtns2 {
    background-color: #f59a23;
    color: #fff;

    border: none !important;
  }
  .wtl_mainBtns2:hover {
    color: #fff !important;
  }
}

// 盒子外层高度
.tableBox {
  height: calc(100% - 260px);
  position: relative;
  width: 100%;
  margin: 0 auto;
  .el-table__common-options {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 40px;
    height: 28px;
    box-sizing: border-box;
    z-index: 5;
    .el-button {
      border: 0;
    }
    .righttagICon {
      width: 40px;
      height: 28px;
      background-color: #f0f0f0;
      display: flex;
      align-content: center;
      justify-content: center;
    }
  }
}

.itemBtn {
  // height: 30px;
  // padding: 0 13px;
  // line-height: 30px;

  .el-button:hover {
    border: 1px solid #ff8108;
  }
  .el-button {
    padding: 8px;
    background-color: #f7f7f7;
    color: #606266;
  }
  .el-dropdown {
    margin: 8px;
  }
}

//一级导航多于出来的颜色
.conten .mainBox .main .topTabs {
  // #6d6a6a
  // background-color: #fff !important;
  height: 22px !important;
}

.el-tabs__item {
  height: 22.5px;
  line-height: 22.5px;
}

//左边不对齐
.el-menu--collapse {
  width: 100%;
}

//左边不对齐
.el-menu {
  border: 0;
}

//绑定class
.HeadInputbox1 {
  height: "";
}

//绑定class
.active {
  height: 40px;
  margin-bottom: 10px;
}

.container {
  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    background-color: #fff !important;
    color: #ef7c1b;
  }
}

.el-tabs__content {
  background-color: #fff;
}

.topTabs {
  .el-tabs--card > .el-tabs__header {
    border: 0;
  }
}

//左上角按钮
.topTabs .rightTips .righttagICon {
  background-color: #6d6a6a !important;

  border-radius: 50%;
}

.el-icon--right {
  margin-left: 0;
}

//左上角按钮
.topTabs .rightTips .el-button:focus,
.topTabs .rightTips .el-button:hover {
  background-color: #fff !important;
}

/* .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  background-color: #fff !important;
  color: #000;
} */

.el-tabs__item.is-active {
  color: #333;
}

//弹出框样式
.el-dialog {
  width: 50%;
  margin-top: 5vh !important;
  .el-dialog__header {
    padding: 10px 0px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    color: #7e7e7e;
  }
  .el-dialog__body {
    padding: 20px !important;
    box-sizing: border-box;
  }
}

.v-modal {
  z-index: 500 !important;
}

.topTabs .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  color: #000 !important;
  font-weight: 600 !important;
  font-size: 13px;
}

//下拉框选中样式
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ff8108;
  color: #fff;
  font-size: 13px;
}

.el-select-dropdown__item.selected {
  color: #ff8108;
}

.el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  background-color: #ff8108;
  color: #fff;
}

.topTabs {
  .el-tabs__nav-scroll {
    background-color: #555555;
  }
}

.el-tooltip__popper.is-dark {
  background: #fff;
  color: #666666;
  box-shadow: 0px 0px 2px #666666;
}

.el-table__body tr.current-row > td {
  background-color: #6eadff !important;
  color: #fff;
}

.el-tabs__nav-next,
.el-tabs__nav-prev {
  width: 30px;
  height: 30px;
  background-color: #555555;
  [class*=" el-icon-"],
  [class^="el-icon-"] {
    margin-left: 9px;
  }
}

// .theme-default
//   .el-table--striped
//   .el-table__body
//   tr.el-table__row--striped.current-row
//   td {
//   background-color: yellow;
// }

a:-webkit-any-link {
  text-decoration: none;
  color: #2c6ade !important;
}

.queryItem1[data-v-c80f4f5e] {
  font-size: 13px !important;
}

.el-switch__label * {
  font-size: 13px;
}

.el-button {
  font-size: 13px !important;
}

.el-input__inner {
  font-size: 13px !important;
}

//确定按钮样式

//取消按钮样式
.cancel {
  .el-button {
    background-color: #f1f1f1;
    color: #333333;
    padding: 6px 13px;
    border: 1px solid #606266;
  }
  .el-button:hover {
    border: 1px solid #ff8108;
  }
}

.el-select-dropdown__item {
  font-size: 13px;
}

.el-menu-item {
  font-size: 13px;
}

.conten .mainBox .aside .el-submenu__title {
  font-size: 13px;
}

.el-submenu__title * {
  font-size: 13px;
}

.el-submenu .el-menu-item {
  font-size: 13px;
}

.rightTips .el-button {
  border: 0;
  background-color: #555555;
}

.el-dropdown {
  width: 29px;
  height: 29px;
  border-right: 50%;
}

.rightTips .el-button:hover {
  border: 0;
  background-color: #555555;
}

.el-upload {
  width: 100px;
  height: 100px;
  line-height: 100px;
}

.el-upload--picture-card i {
  font-size: 20px;
}

.el-table__common-options .el-dropdown {
  .el-button {
    color: #666666;
  }
}

.box3 .formbox {
  margin: 0;
}
.el-table th.el-table__cell > .cell {
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-table .cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  line-height: 16px;
}

/* .dialog-footer{
      .el-button{
        color: #fff;
        background-color: #f0a261;
        border: 0;
        padding: 6px 10px;
      }
      .el-button:hover{
        color: #fff;
        border: 0;
        background-color: #f0a261;
        padding: 6px 10px;
      }
    } */
.topTabs .el-tabs--card > .el-tabs__header .el-tabs__item {
  font-size: 13px;
}

.container .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  font-size: 13px;
  font-weight: 600;
}

.el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  font-size: 13px;
}

.el-dropdown-menu__item {
  font-size: 13px !important;
}

.rightTips .el-button {
  color: #fff;
}

.rightTips .el-button:hover {
  color: #f0a261;
}

.el-dropdown .el-button :hover {
  color: #f0a261;
}

.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  font-size: 13px;
}

.container .box .rows[data-v-45fe49ab] {
  justify-content: center;
}

.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}

.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f0a261;
  border-color: #f0a261;
}

.el-checkbox__inner:hover {
  border: 1px solid #f0a261;
}

.el-tabs__item {
  font-size: 13px;
}

.el-table th,
.el-table tr {
  border: 1px solid #fff;
  border-top: 0;
}

.el-dialog__body {
  font-size: 13px;
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}

.el-upload {
  span {
    font-size: 13px;
  }
}

//弹窗单按钮样式
.el-select {
  width: 100%;
}

.ordinarybut.el-button {
  padding: 6px 12px;
  min-width: 64px;
  color: #606266;
  background-color: #f7f7f7;
  border: 1px solid #606266;
  display: flex;
  justify-content: center;
}

.ordinarybut.el-button:hover {
  padding: 6px 12px;
  color: #606266 !important;
  background-color: #f7f7f7;
  border: 1px solid #f0a261;
}

.seniorbut.el-button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 6px 12px;
  width: 64px;
  border: 1px solid #f0a261;
  background-color: #f0a261;
  color: #fff;
}

.seniorbut.el-button:hover {
  padding: 6px 12px;
  border: 1px solid #f0a261;
  background-color: #f0a261;
  color: #fff;
}
//鼠标停留的颜色
.el-table__body tr.hover-row > td.el-table__cell {
  background-color: #ffeab6;
}
//当前选中行颜色
.el-table__body tr.current-row > td {
  background-color: #ffeab6 !important;
  color: #606266 !important;
}
//斑马纹
.el-table .warning-row {
  background: #f8f8f8;
}

.pwdform {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  .scanning {
    width: 100%;
    display: flex;
    .determine {
      margin-top: 22px;
      margin-left: 10px;
    }
  }
  .singleinp:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .singleinp {
    width: 80% !important;
    margin: 20px 0 !important;
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-bottom: 10px;
    .singleinpleft {
      width: 90px;
      min-width: 90px;
      color: #606266;
      text-align: right;
      margin-right: 10px;
    }
    .singleinpright {
      width: 100%;
    }
  }
}

/*.el-textarea__inner {*/
/*border: 0;*/
/*}*/

/*      lx          */
.newForm {
  width: 100%;
  margin: 10px auto;
  .queryItem1 {
    width: 30%;
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    margin: 3px 1%;
    align-items: center;
    font-size: 13px;
  }
  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    margin-bottom: 0;
    min-height: 28px;

    line-height: 26px;
  }
  .el-form-item {
    width: 100%;
  }
  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .el-input__inner {
    min-height: 28px;
    height: 28px;
    line-height: 28px;
    border: 0;
    width: 100%;
  }
  .M_H {
    margin: 10px 0;
  }

  .M {
    border: 1px solid #e0e0e0;
    box-shadow: 0px 0px 4px #e0e0e0;
    padding: 20px;
    width: 99.5%;
    box-sizing: border-box;
  }
}

.inlineDiv {
  display: flex;
}

/*       lx          */
.pwdform {
  .singleinp {
    .el-input__inner {
      border: 0;
      height: 30px;
      line-height: 30px;
    }
  }
}

.topTabs .el-tabs--card > .el-tabs__header .el-tabs__item {
  font-size: 13px;
}
.container .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
  font-size: 13px;
  font-weight: 600;
}
.el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
  font-size: 13px;
}
.el-dropdown-menu__item {
  font-size: 13px !important;
}
.rightTips .el-button {
  color: #fff;
}
.rightTips .el-button:hover {
  color: #f0a261;
}
.el-dropdown .el-button :hover {
  color: #f0a261;
}

.el-tabs--top.el-tabs--card > .el-tabs__header .el-tabs__item:last-child {
  font-size: 13px;
}
.container .box .rows[data-v-45fe49ab] {
  justify-content: center;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #606266;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f0a261;
  border-color: #f0a261;
}
.el-checkbox__inner:hover {
  border: 1px solid #f0a261;
}
.el-tabs__item {
  font-size: 13px;
}
.el-table th,
.el-table tr {
  border: 1px solid #fff;
  border-top: 0;
}
.el-dialog__body {
  font-size: 13px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
.el-upload {
  span {
    font-size: 13px;
  }
}
//弹窗单按钮样式
.el-select {
  width: 100%;
}

.ordinarybut.el-button {
  padding: 6px 12px;
  min-width: 64px;
  color: #606266;
  background-color: #f7f7f7;
  border: 1px solid #606266;
  display: flex;
  justify-content: center;
}
.ordinarybut.el-button:hover {
  padding: 6px 12px;
  color: #606266 !important;
  background-color: #f7f7f7;
  border: 1px solid #f0a261;
}
.seniorbut.el-button {
  display: flex;
  justify-content: center;
  align-content: center;
  padding: 6px 12px;
  width: 64px;
  border: 1px solid #f0a261;
  background-color: #f0a261;
  color: #fff;
}
.seniorbut.el-button:hover {
  padding: 6px 12px;
  border: 1px solid #f0a261;
  background-color: #f0a261;
  color: #fff;
}

.pwdform {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;
  .scanning {
    width: 100%;
    display: flex;
    .determine {
      margin-top: 22px;
      margin-left: 10px;
    }
  }
  .singleinp:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .singleinp {
    width: 80% !important;
    margin: 20px 0 !important;
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 13px;
    margin-bottom: 10px;
    .singleinpleft {
      width: 90px;
      min-width: 90px;
      color: #606266;
      text-align: right;
      margin-right: 10px;
    }
    .singleinpright {
      width: 100%;
    }
  }
}
/*.el-textarea__inner {*/
/*border: 0;*/
/*}*/
//弹窗输入框样式
.newForm {
  width: 100%;
  margin: 10px auto;
  .queryItem1 {
    width: 30%;
    border: 1px solid #cccccc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 13px;
  }
  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    margin-bottom: 0;
    min-height: 28px;

    line-height: 26px;
  }
  .el-form-item {
    width: 100%;
  }
  .queryItem1:hover {
    border: 1px solid #f0a261;
    box-shadow: 0px 0px 5px #f0a261;
  }
  .el-input__inner {
    min-height: 28px;
    height: 28px;
    line-height: 28px;
    border: 0;
    width: 100%;
  }
  .M_H {
    margin: 10px 0;
  }

  .M {
    border: 1px solid #e0e0e0;
    box-shadow: 0px 0px 4px #e0e0e0;
    padding: 20px;
    width: 99.5%;
    box-sizing: border-box;
  }
}

.inlineDiv {
  display: flex;
}
.pwdform {
  .singleinp {
    .el-input__inner {
      border: 0;
      height: 30px;
      line-height: 30px;
    }
  }
}
.newForm {
  .queryItem1 {
    .el-input-number.is-controls-right .el-input-number__decrease,
    .el-input-number.is-controls-right .el-input-number__increase {
      line-height: 14px !important;
    }
  }
}
//弹框备注边框去除
.newForm {
  .queryItem1 {
    .el-textarea {
      .el-textarea__inner {
        border: 0 !important;
      }
    }
  }
}
//禁用样式
.el-input.is-disabled .el-input__inner {
  color: #666;
}

.right_inpu {
  flex: 1 !important;
}
//时间选择器图标居中
.timebox {
  .el-date-editor .el-range__icon {
    line-height: 23px !important;
  }
  .el-date-editor .el-range-separator {
    line-height: 23px !important;
  }
  .el-date-editor .el-range__close-icon {
    line-height: 23px !important;
  }
}
//表格里面按钮
.el-table td.el-table__cell div {
  display: flex;
}
.el-table .el-table__cell {
  padding: 0 !important;
  height: 32px !important;
}

// 新的查询按钮样式(右上角)
.wtl_btnList {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .wtl_btnList1 {
    flex: 1;
  }

  .wtl_queryBoxNew {
    display: flex;
    align-items: center;

    .condition {
      color: #2c6ae1;
      font-size: 13px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
// 时间查询条件新样式
.wtlTimeBox {
  width: 370px !important;
  min-width: 370px !important;
  .wtl_timeStyle {
    display: flex;
    align-items: center;

    .el-input--suffix .el-input__inner {
      padding-right: 10px !important;
    }
  }

  .wtlTimeLabel {
    width: 80px !important;
  }
}

.linkColor {
  color: #2c6ae1;
  cursor: pointer;
}

// 新加   随波===================================================================
// 主题色
$mainColor: #ef7c0a;
// 按钮边框
$btnBorderColor: #cccccc;
// $btnBorderColor: #606266;
// 查询条件边框线
$borderColor: #cccccc;
// 表格右边按钮背景色
$TbRightColor: #0390cf;

.glo_mainColor {
  color: $mainColor !important;
}
// =============== 列表顶部按钮样式(全局)=========
.glo_btnList {
  display: flex;
  justify-content: space-between;
  // padding-bottom: 10px;

  .el-button {
    min-width: 64px;
    padding: 5px;
  }

  .glo_btnList_left {
    flex: 1;
    .glo_btnItems {
      border: 1px solid $btnBorderColor;
      background-color: #fff;
      color: #333;
      padding: 5px;
      margin-left: 10px;
      margin-bottom: 8px;
    }

    .el-button:hover {
      border: 1px solid $mainColor;
      // border: 1px solid #cccccc;
      color: $mainColor;
    }
  }

  .glo_btnList_right {
    display: flex;
    align-items: center;
    margin-left: 20px;
    max-width: 180px;
    margin-bottom: 10px;

    .textBtn {
      font-size: 13px;
      margin-left: 12px;
      color: #2c6ae1;
      cursor: pointer;
    }
  }

  .topDropDown {
    margin-left: 10px;
  }
  .el-button--primary {
    background-color: #fff;
    color: #333;
    border: 1px solid $btnBorderColor;
  }
}
// 页面查询条件样式

.glo_form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  height: 40px;
  overflow: hidden;

  //element UI
  .el-form-item__label {
    line-height: 30px;
    height: 30px;
    text-align: left;
    width: 90px !important;
  }
  .el-form-item {
    margin-bottom: 10px;
  }

  // 除了时间外的其他查询条件样式
  .queryItems {
    width: 230px;
    margin-right: 10px;
    display: flex;
    border: 1px solid $borderColor;
    padding: 0 5px;
    height: 33px;
    line-height: 33px;
    box-sizing: border-box;
    border-radius: 4px;

    .el-form-item__content {
      height: 30px;
      line-height: 30px;
      // overflow: hidden;
    }
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      border: 0;
      width: 120px;
      padding: 0 4px;
    }
    // 下拉选择框右边图标
    .el-input__icon {
      line-height: 30px;
    }
  }
  .queryItems:hover {
    border: 1px solid $mainColor;
    box-shadow: 0px 0px 5px $mainColor;
  }
  // 时间查询
  .queryItemsTimes {
    width: 470px;
    margin-right: 10px;
  }
  // 全字段查询
  .queryItemsAllQuery {
    width: 470px;
    margin-right: 10px;

    .el-form-item__content {
      flex: 1;
    }

    .AllQueryItems {
      display: flex;

      .AllQuerySelect {
        flex: 1;
        border-right: 1px solid $borderColor;
      }
      .AllQueryInput {
        flex: 1;
      }
    }
  }
}
// 展开查询条件时样式
.hasMoreParam {
  height: auto;
}

// ============= 表格 start ================

.glo_Table {
  // 右边固定按钮
  .tabRightBtns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .el-button {
      padding: 5px 6px;
      font-size: 13px;
    }
    .el-dropdown {
      font-size: 13px;
      border-radius: 4px;
    }

    // 右边固定按钮样式
    .rbtns {
      // min-width: 50px;
      color: #fff;
      background-color: $TbRightColor;
      margin-left: 5px;
      margin-right: 5px;
    }

    .el-button:hover {
      background-color: $mainColor;
      border: 1px solid $mainColor;
    }
  }
  // 表头颜色
  .el-table th.el-table__cell {
    background-color: #f0f0f0;
  }
  .el-table .el-table__cell {
    padding: 0;
  }
  .el-table th.el-table__cell > .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #fff;
  }

  .el-table tr {
    height: 34px;
  }
  .el-table .el-table__cell {
    text-overflow: ellipsis;
  }

  //鼠标停留的颜色
  .el-table__body tr.hover-row > td.el-table__cell {
    background-color: #ffeab6 !important;
  }
  //当前选中行颜色
  .el-table__body tr.current-row > td {
    background-color: #ffeab6 !important;
  }

  .el-table__row--striped {
    background-color: #f00;
  }
  // 可以点击跳转的列
  .linkTabel {
    color: #02a7f0;
    cursor: pointer;
  }
}
// ============= 表格 end ================

// ============= 弹窗表单样式 start ================
.glo_dialogForm {
  min-height: 200px;
  .el-form {
    display: flex;
    flex-wrap: wrap;
  }

  .el-input__inner {
    border: none;
  }

  .diaForm {
    display: flex;
    flex-wrap: wrap;

    .dialogFormItems {
      width: 30%;
      min-width: 260px;
      border: 1px solid $borderColor;
      margin-right: 3%;
      height: 33px;
      line-height: 33px;
      border-radius: 4px;
      // overflow: hidden;

      .el-form-item__label {
        height: 30px;
        line-height: 30px;
      }
      .el-input__inner,
      .el-form-item__content,
      .el-input__icon {
        height: 30px;
        line-height: 30px;
        // overflow: hidden;
      }
    }
    .dialogFormItemsTime {
      width: 30%;
      min-width: 345px;
      border: 1px solid $borderColor;
      margin-right: 3%;
      height: 33px;
      line-height: 33px;
      border-radius: 4px;
      // overflow: hidden;

      .el-form-item__label {
        height: 30px;
        line-height: 30px;
      }
      .el-input__inner,
      .el-form-item__content,
      .el-input__icon {
        height: 30px;
        line-height: 30px;
        // overflow: hidden;
      }
    }

    .dislogFormTextarea {
      min-width: 360px;
      width: 100%;
      border: 1px solid #cccccc;
      border-radius: 4px;
      margin-right: 3%;

      .el-textarea__inner {
        border: 0;
      }
    }
  }
}

// ============= 弹窗表单样式 end ================

// 页面表单格式(如新建运单页面的)
.glo_formBox {
  display: flex;
  flex-wrap: wrap;

  .el-form-item {
    margin-bottom: 18px;
    width: 23%;
    // min-width: 260px;
    min-width: 275px;
    margin-right: 1%;
    border: 1px solid $borderColor;
    border-radius: 4px;
    height: 33px;
    line-height: 33px;
    overflow: hidden;

    .el-select {
      width: 100%;
    }
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .el-input__inner,
    .el-form-item__content,
    .el-input__icon,
    .el-form-item__label {
      border: 0;
      height: 30px;
      line-height: 30px;
      // overflow: hidden;
    }

    .el-radio__input.is-checked .el-radio__inner {
      background-color: $mainColor;
      border-color: $mainColor;
    }
    .el-radio__input.is-checked + .el-radio__label {
      color: $mainColor;
    }
  }
  .textArea {
    .el-textarea__inner {
      border: none;
    }
    .el-form-item__content {
      height: auto;
    }
  }
  //特殊样式,独占一行带按钮的
  .teSu {
    display: flex;
    width: 100%;
  }
}

.glo_disFlex {
  display: flex;
  align-items: center;
}

.el-cascader {
  height: 30px;
  line-height: 30px;
}

.el-message {
  top: 180px !important;
}
// .el-message .el-icon-success {
// font-size: 18px;
// }
// .el-message--success .el-message__content {
//   font-size: 18px;
// }

.linkColor {
  color: #0390cf;
  cursor: pointer;
}
</style>
