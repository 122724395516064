import {
    Api
} from "@/assets/js/api";



// :remote-method="(val) => getCustomerList(val, scope.$index)"
// 根据对象类型获取对象的下拉列表
async function getObjectUserDownList(key, name) {
    let resData = "";
    await Api.wtl_getObjectUserDownList({
        key: key,
        name: name,
        pageStart: 1,
        pageTotal: 200,
    })
        .then((res) => {
            resData = res.data.result.data || [];

            // this.$COMAPI.getObjectUserDownList("employee").then(res=>{}); //获取员工列表
            // this.$COMAPI.getObjectUserDownList("dept").then(res=>{}); //获取部门列表
            // this.$COMAPI.getObjectUserDownList("storage").then(res=>{}); //获取网点列表
        }).catch(error => {

        })

    return resData;
}

// 根据字典类型获取字典值下拉列表
async function getConfDictValDownList(dictType) {
    let resData = "";
    await Api.wtl_getConfDictValDownList({
        dictType: dictType
    })
        .then((res) => {
            resData = res.data.result || [];
            // 调用
            // this.$COMAPI.getConfDictValDownList("package_name").then(res=>{}); //获取包装
            // this.$COMAPI.getConfDictValDownList("waybill_type").then(res=>{}); //货物类型
            // this.$COMAPI.getConfDictValDownList("rn_order_require").then(res=>{}); //回单类型
            // this.$COMAPI.getConfDictValDownList("send_method").then(res=>{}); //送货方式
            // this.$COMAPI.getConfDictValDownList("receive_method").then(res=>{}); //提货方式
            // this.$COMAPI.getConfDictValDownList("transport_method").then(res=>{}); //运输方式
            // this.$COMAPI.getConfDictValDownList("settlement_method").then(res=>{}); //付款方式
            // this.$COMAPI.getConfDictValDownList("start_car_check_item").then(res=>{}); //出车检查项
            // this.$COMAPI.getConfDictValDownList("exception_type").then(res=>{}); //异常类型
            // this.$COMAPI.getConfDictValDownList("handling_order_type").then(res=>{}); //装卸类型
            // this.$COMAPI.getConfDictValDownList("settlement_method").then(res=>{}); //获取付款方式
            // this.$COMAPI.getConfDictValDownList("object_status").then(res=>{}); //获取结算对象类型
            // this.$COMAPI.getConfDictValDownList("insurance_type").then(res=>{}); //险种
            // this.$COMAPI.getConfDictValDownList("customer_level").then(res=>{}); //客户等级

            //     <el-option
            //     v-for="(item, index) in handling_order_type_list"
            //     :key="index"
            //     :label="item.key"
            //     :value="item.confDictId"
            //   ></el-option>

        }).catch(error => {

        })

    return resData;
}

// 获取下拉列表
async function getStatusValList(status) {
    let resData = "";
    await Api.getStatusValList({
        tableAndFieldName: status
    })
        .then((res) => {
            resData = res.data.result || [];

            // this.$comAPI.getStatusValList('aaa').then(res => {
            //     console.log('res', res)
            // })

            // 包裹状态: ==========> package_v1.package_status
            // 拒收单拒收状态: ====> bill_rejection_v1.handle_status
            // 验货单验货状态: ====> bill_inspection_v1.check_status
            // 审批状态:==========> finance_in_record_v1.approve_status
            // 核销状态:==========> finance_in_record_v1.writeoff_status
            // 单据类型:==========> finance_in_record_v1.document_status
            // 打包状态:==========> bill_pack_v1.pack_status
            // 第三方平台类型:==========> goods_v2.platform_type
            // 商品状态:==========> goods_v2.goods_status

        }).catch(error => {

        })

    return resData;
}



// 不分页查询
async function runPageReportDetails(number, arrs) {
    let resData = "";
    await Api.runPageReportDetails({
        number: number,
        conditionReqs: arrs || []
    })
        .then((res) => {
            resData = res.data.result?.datas || [];
        });
    return resData;
}

// 分页查询
async function runPageReportFN(number, key, val) {
    let resData = "";
    await Api.runPageReport({
        conditionReqs: [
            {
                conditonKey: key || "",
                conditonVal: val || "",
            },
        ],
        number: number,
        pageStart: 1,
        pageTotal: 100,
    })
        .then((res) => {
            resData = res.data.result.datas?.data || [];
        });
    return resData;
}




export default {
    getObjectUserDownList, // 根据对象类型获取对象的下拉列表
    getConfDictValDownList, // 根据字典类型获取字典值下拉列表
    getStatusValList,
    runPageReportFN,   //分页动态查询
    runPageReportDetails, //不分页动态查询
}