import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

const router = new Router({
  routes: [{
    path: "/login",
    component: (resolve) => require(["@/pages/login"], resolve),
    meta: {
      title: "登录",
      keepAlive: false,
    },
  },
  // 验证码登录
  {
    path: "/pages/getCodeLogin/getCodeLogin",
    component: (resolve) =>
      require(["@/pages/getCodeLogin/getCodeLogin"], resolve),
    meta: {
      title: "登录",
      keepAlive: false,
    },
  },
  // 绿界
  {
    path: "/pages/getStoreName",
    component: (resolve) =>
      require(["@/pages/getStoreName/getStoreName"], resolve),
    meta: {
      title: "绿界授权",
      keepAlive: false,
    },
  },




  {
    path: "/MessagePanel",
    component: (resolve) => require(["@/pages/MessagePanel"], resolve),
    meta: {
      title: "菜单管理",
      keepAlive: false,
    },
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/baseIndex",
    name: "主页",
    component: (resolve) => require(["@/pages/baseIndex"], resolve),
    meta: {
      title: "主页",
      keepAlive: false,
    },
    redirect: "/controlPanel",
    children: [
      // 业务管理
      // ==>综合查询
      {
        path: "/BusinessManagement/comprehensiveQuery",
        component: (resolve) =>
          require(["@/pages/BusinessManagement/comprehensiveQuery"], resolve),
        meta: {
          title: "综合查询",
          keepAlive: true,
        },
      },
      // ==>添加包裹
      {
        path: "/BusinessManagement/addPacks",
        component: (resolve) =>
          require(["@/pages/BusinessManagement/addPacks"], resolve),
        meta: {
          title: "添加包裹",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/packsDetails",
        component: (resolve) =>
          require(["@/pages/BusinessManagement/packsDetails"], resolve),
        meta: {
          title: "包裹详情",
          keepAlive: false,
        },
      },
      {
        path: "/BusinessManagement/dabaoDetails",
        component: (resolve) =>
          require(["@/pages/BusinessManagement/dabaoDetails"], resolve),
        meta: {
          title: "打包详情",
          keepAlive: true,
        },
      },
      // {
      //   path: "/BusinessManagement/Pickingconfirmation",
      //   component: (resolve) =>
      //     require([
      //       "@/pages/BusinessManagement/Pickingconfirmation",
      //     ], resolve),
      //   meta: {
      //     title: "打包申请",
      //     keepAlive: true,
      //   },
      // },
      {
        path: "/BusinessManagement/Collage",
        component: (resolve) =>
          require(["@/pages/BusinessManagement/Collage"], resolve),
        meta: {
          title: "拼团管理",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/JoinManagement",
        component: (resolve) =>
          require(["@/pages/BusinessManagement/JoinManagement"], resolve),
        meta: {
          title: "加入拼团",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/SignFor",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/SignFor",
          ], resolve),
        meta: {
          title: "到库签收",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/PackageWarehousing",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/PackageWarehousing",
          ], resolve),
        meta: {
          title: "包裹入库",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/IntelligentWarehous",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/IntelligentWarehous",
          ], resolve),
        meta: {
          title: "智能入库",
          keepAlive: false,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/ReceiptConfirmation",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/ReceiptConfirmation",
          ], resolve),
        meta: {
          title: "入库确认",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/Inventory",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/Inventory",
          ], resolve),
        meta: {
          title: "仓库库存",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/PackageShelf",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/PackageShelf",
          ], resolve),
        meta: {
          title: "包裹上架",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/RejectReturn",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/RejectReturn",
          ], resolve),
        meta: {
          title: "拒收退货",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/RejectionTreatment",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/RejectionTreatment",
          ], resolve),
        meta: {
          title: "拒收处理",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/InspectionManagement",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/InspectionManagement",
          ], resolve),
        meta: {
          title: "验货管理",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/InspectionHandling",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/InspectionHandling",
          ], resolve),
        meta: {
          title: "验货处理",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/ApplicationPackaging",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/ApplicationPackaging",
          ], resolve),
        meta: {
          title: "申请打包",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/Warehousing/PrintPickingList",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Warehousing/PrintPickingList",
          ], resolve),
        meta: {
          title: "打印拣货单",
          keepAlive: false,
        },
      },
      {
        path: "/BusinessManagement/Pickingconfirmation",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/Pickingconfirmation",
          ], resolve),
        meta: {
          title: "编辑申请打包",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/ExWarehouse/PickingOffTheShelf",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/PickingOffTheShelf",
          ], resolve),
        meta: {
          title: "拣货下架",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/ExWarehouse/DownConfirm",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/DownConfirm",
          ], resolve),
        meta: {
          title: "下架确认",
          keepAlive: false,
        },
      },
      {
        path: "/BusinessManagement/ExWarehouse/WarehousePackaging",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/WarehousePackaging",
          ], resolve),
        meta: {
          title: "仓库打包",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/ExWarehouse/PackingConfirmation",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/PackingConfirmation",
          ], resolve),
        meta: {
          title: "打包确认",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/ExWarehouse/EditPackingConfirmation",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/EditPackingConfirmation",
          ], resolve),
        meta: {
          title: "修改打包单",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/ExWarehouse/DeliveryConfirmation",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/DeliveryConfirmation.vue",
          ], resolve),
        meta: {
          title: "出库确认",
          keepAlive: true,
        },
      },
      {
        path: "/BusinessManagement/ExWarehouse/CustomerSignature",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/CustomerSignature",
          ], resolve),
        meta: {
          title: "客户签收",
          keepAlive: true,
        },
      },

      //数据看板
      {
        path: "/controlPanel",
        component: (resolve) => require(["@/pages/controlPanel"], resolve),
        meta: {
          title: "控制面板",
          keepAlive: true,
        },
      },
      // ==================hw 开始==================
      //异常处理
      {
        path: "/exceptionhandling/Parcelclaim",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/Parcelclaim"], resolve),
        meta: {
          title: "包裹认领",
          keepAlive: true,
        },
      },
      {
        path: "/exceptionhandling/Abnormalpackage",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/Abnormalpackage"], resolve),
        meta: {
          title: "异常包裹",
          keepAlive: true,
        },
      },
      {
        path: "/exceptionhandling/abnormalType",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/abnormalType"], resolve),
        meta: {
          title: "异常类型维护",
          keepAlive: true,
        },
      },
      {
        path: "/exceptionhandling/Newexception",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/Newexception"], resolve),
        meta: {
          title: "新建异常",
          keepAlive: false,
        },
      },
      {
        path: "/exceptionhandling/NewQuestion",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/NewQuestion"], resolve),
        meta: {
          title: "新建问题",
          keepAlive: false,
        },
      },
      {
        path: "/exceptionhandling/complaint",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/complaint"], resolve),
        meta: {
          title: "投诉建议及问题",
          keepAlive: true,
        },
      },
      {
        path: "/exceptionhandling/questionType",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/questionType"], resolve),
        meta: {
          title: "问题类型",
          keepAlive: true,
        },
      },
      {
        path: "/exceptionhandling/questionDealType",
        component: (resolve) =>
          require(["@/pages/exceptionhandling/questionDealType"], resolve),
        meta: {
          title: "问题处理类型",
          keepAlive: true,
        },
      },
      //财务管理
      {
        path: "/Finance/ReceivableBilling",
        component: (resolve) =>
          require(["@/pages/Finance/ReceivableBilling"], resolve),
        meta: {
          title: "应收计费",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/modifyFee",
        component: (resolve) =>
          require(["@/pages/Finance/modifyFee"], resolve),
        meta: {
          title: "费项修改",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/WriteOff",
        component: (resolve) =>
          require(["@/pages/Finance/WriteOff"], resolve),
        meta: {
          title: "收款核销",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/BalanceSheet",
        component: (resolve) =>
          require(["@/pages/Finance/BalanceSheet"], resolve),
        meta: {
          title: "应收会员余额表",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/Recharge",
        component: (resolve) =>
          require(["@/pages/Finance/Recharge"], resolve),
        meta: {
          title: "会员充值",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/RechargeApplication",
        component: (resolve) =>
          require(["@/pages/Finance/RechargeApplication"], resolve),
        meta: {
          title: "充值申请",
          keepAlive: false,
        },
      },
      {
        path: "/Finance/OtherIncome",
        component: (resolve) =>
          require(["@/pages/Finance/OtherIncome"], resolve),
        meta: {
          title: "其他收入",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/IncomeApplication",
        component: (resolve) =>
          require(["@/pages/Finance/IncomeApplication"], resolve),
        meta: {
          title: "收入申请",
          keepAlive: false,
        },
      },
      {
        path: "/Finance/CustomerPlus",
        component: (resolve) =>
          require(["@/pages/Finance/CustomerPlus"], resolve),
        meta: {
          title: "客户加收",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/KehuApplication",
        component: (resolve) =>
          require(["@/pages/Finance/KehuApplication"], resolve),
        meta: {
          title: "客户加收申请",
          keepAlive: true,
        },
      },
      {
        path: "/Finance/CustomerApplication",
        component: (resolve) =>
          require(["@/pages/Finance/CustomerApplication"], resolve),
        meta: {
          title: "代理加收申请",
          keepAlive: false,
        },
      },
      //应付管理
      {
        path: "/PayableManagement/Import",
        component: (resolve) =>
          require(["@/pages/PayableManagement/Import"], resolve),
        meta: {
          title: "应付费用",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/apply",
        component: (resolve) =>
          require(["@/pages/PayableManagement/apply"], resolve),
        meta: {
          title: "应付申请",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/PaymentWriteOff",
        component: (resolve) =>
          require(["@/pages/PayableManagement/PaymentWriteOff"], resolve),
        meta: {
          title: "付款核销",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/AgencyBalance",
        component: (resolve) =>
          require(["@/pages/PayableManagement/AgencyBalance"], resolve),
        meta: {
          title: "应付代理余额表",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/OtherExpenses",
        component: (resolve) =>
          require(["@/pages/PayableManagement/OtherExpenses"], resolve),
        meta: {
          title: "其他支出",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/IncomeApplication",
        component: (resolve) =>
          require(["@/pages/PayableManagement/IncomeApplication"], resolve),
        meta: {
          title: "收支申请",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/ChannelAgent",
        component: (resolve) =>
          require(["@/pages/PayableManagement/ChannelAgent"], resolve),
        meta: {
          title: "渠道代理",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/NewChannel",
        component: (resolve) =>
          require(["@/pages/PayableManagement/NewChannel"], resolve),
        meta: {
          title: "新建渠道",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/ModifyCountry",
        component: (resolve) =>
          require(["@/pages/PayableManagement/ModifyCountry"], resolve),
        meta: {
          title: "修改国家",
          keepAlive: false,
        },
      },
      {
        path: "/PayableManagement/AgencySurcharge",
        component: (resolve) =>
          require(["@/pages/PayableManagement/AgencySurcharge"], resolve),
        meta: {
          title: "代理加收",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/accountNumber",
        component: (resolve) =>
          require(["@/pages/PayableManagement/accountNumber"], resolve),
        meta: {
          title: "资金账号",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/DailyAccount",
        component: (resolve) =>
          require(["@/pages/PayableManagement/DailyAccount"], resolve),
        meta: {
          title: "资金流水账",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/BalanceSheet",
        component: (resolve) =>
          require(["@/pages/PayableManagement/BalanceSheet"], resolve),
        meta: {
          title: "资金余额表",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/channelManagement",
        component: (resolve) =>
          require(["@/pages/PayableManagement/channelManagement"], resolve),
        meta: {
          title: "渠道管理",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/CustomerQuotation",
        component: (resolve) =>
          require(["@/pages/PayableManagement/CustomerQuotation"], resolve),
        meta: {
          title: "客户报价",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/NewCustomer",
        component: (resolve) =>
          require(["@/pages/PayableManagement/NewCustomer"], resolve),
        meta: {
          title: "新建客户价格",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/FeeService",
        component: (resolve) =>
          require(["@/pages/PayableManagement/FeeService"], resolve),
        meta: {
          title: "费项与服务",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/FeeServiceCategory",
        component: (resolve) =>
          require(["@/pages/PayableManagement/FeeServiceCategory"], resolve),
        meta: {
          title: "费项分类",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/transport",
        component: (resolve) =>
          require(["@/pages/PayableManagement/transport"], resolve),
        meta: {
          title: "运输方式",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/category",
        component: (resolve) =>
          require(["@/pages/PayableManagement/category"], resolve),
        meta: {
          title: "渠道类别",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/VolumeDivision",
        component: (resolve) =>
          require(["@/pages/PayableManagement/VolumeDivision"], resolve),
        meta: {
          title: "材积除",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/QuotationLevel",
        component: (resolve) =>
          require(["@/pages/PayableManagement/QuotationLevel"], resolve),
        meta: {
          title: "客户报价等级",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/WeightCalculationRules",
        component: (resolve) =>
          require([
            "@/pages/PayableManagement/WeightCalculationRules",
          ], resolve),
        meta: {
          title: "计费重量计算规则",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/WeightCarryRule",
        component: (resolve) =>
          require(["@/pages/PayableManagement/WeightCarryRule"], resolve),
        meta: {
          title: "计费重量进位规则",
          keepAlive: true,
        },
      },
      {
        path: "/PayableManagement/NewQuotation",
        component: (resolve) =>
          require(["@/pages/PayableManagement/NewQuotation"], resolve),
        meta: {
          title: "新建报价",
          keepAlive: true,
        },
      },
      //会员管理
      {
        path: "/MemberManagement/Administration",
        component: (resolve) =>
          require(["@/pages/MemberManagement/Administration"], resolve),
        meta: {
          title: "会员管理",
          keepAlive: true,
        },
      },
      {
        path: "/MemberManagement/addressList",
        component: (resolve) =>
          require(["@/pages/MemberManagement/addressList"], resolve),
        meta: {
          title: "地址管理",
          keepAlive: true,
        },
      },

      {
        path: "/MemberManagement/blacklist",
        component: (resolve) =>
          require(["@/pages/MemberManagement/blacklist"], resolve),
        meta: {
          title: "黑名单",
          keepAlive: true,
        },
      },
      {
        path: "/MemberManagement/MemberFlow",
        component: (resolve) =>
          require(["@/pages/MemberManagement/MemberFlow"], resolve),
        meta: {
          title: "会员流水账",
          keepAlive: true,
        },
      },
      {
        path: "/MemberManagement/coupon",
        component: (resolve) =>
          require(["@/pages/MemberManagement/coupon"], resolve),
        meta: {
          title: "优惠卷管理",
          keepAlive: true,
        },
      },
      {
        path: "/MemberManagement/AgentManagement",
        component: (resolve) =>
          require(["@/pages/MemberManagement/AgentManagement"], resolve),
        meta: {
          title: "代理管理",
          keepAlive: false,
        },
      },
      {
        path: "/MemberManagement/BonusSetting",
        component: (resolve) =>
          require(["@/pages/MemberManagement/BonusSetting"], resolve),
        meta: {
          title: "奖金设置",
          keepAlive: false,
        },
      },
      {
        path: "/MemberManagement/BonusFlow",
        component: (resolve) =>
          require(["@/pages/MemberManagement/BonusFlow"], resolve),
        meta: {
          title: "奖金流水",
          keepAlive: false,
        },
      },
      {
        path: "/MemberManagement/BalanceSheet",
        component: (resolve) =>
          require(["@/pages/MemberManagement/BalanceSheet"], resolve),
        meta: {
          title: "代理余额总表",
          keepAlive: false,
        },
      },
      {
        path: "/MemberManagement/reflect",
        component: (resolve) =>
          require(["@/pages/MemberManagement/reflect"], resolve),
        meta: {
          title: "提现管理",
          keepAlive: false,
        },
      },
      {
        path: "/MemberManagement/AgentRanking",
        component: (resolve) =>
          require(["@/pages/MemberManagement/AgentRanking"], resolve),
        meta: {
          title: "代理排行榜",
          keepAlive: false,
        },
      },
      //系统管理
      {
        path: "/systemManagement/BasicSettings/CountryRegion",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/CountryRegion",
          ], resolve),
        meta: {
          title: "国家与地区",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/BasicSettings/AddresSselection",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/AddresSselection",
          ], resolve),
        meta: {
          title: "州省市区县",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/BasicSettings/CargoCategory",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/CargoCategory",
          ], resolve),
        meta: {
          title: "货物类别",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/BasicSettings/InspectionRequirements",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/InspectionRequirements",
          ], resolve),
        meta: {
          title: "验货要求",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/BasicSettings/PackagingServices",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/PackagingServices",
          ], resolve),
        meta: {
          title: "包装服务",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/BasicSettings/ProblemType",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/ProblemType",
          ], resolve),
        meta: {
          title: "问题类型",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/Calendar/List",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/Calendar/List",
          ], resolve),
        meta: {
          title: "运输行事历列表",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/Calendar/TimeMaintenance",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/Calendar/TimeMaintenance",
          ], resolve),
        meta: {
          title: "行事历时间维护",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/BasicSettings/express",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/express",
          ], resolve),
        meta: {
          title: "快递公司",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/BasicSettings/ProtocolManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/ProtocolManagement",
          ], resolve),
        meta: {
          title: "接受协议管理",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/WarehouseManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/WarehouseManagement",
          ], resolve),
        meta: {
          title: "仓库管理",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/WarehouseCargoArea",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/WarehouseCargoArea",
          ], resolve),
        meta: {
          title: "仓库货区",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/WarehouseShelves",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/WarehouseShelves",
          ], resolve),
        meta: {
          title: "仓库货架",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/organizationalStructure",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/organizationalStructure",
          ], resolve),
        meta: {
          title: "组织架构",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/EmployeeManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/EmployeeManagement",
          ], resolve),
        meta: {
          title: "员工管理",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/department",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/department",
          ], resolve),
        meta: {
          title: "部门管理",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/bankManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/bankManagement",
          ], resolve),
        meta: {
          title: "银行管理",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/OrganizationWarehouse/currency",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/OrganizationWarehouse/currency",
          ], resolve),
        meta: {
          title: "货币管理",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/AccountAuthority/accountManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/AccountAuthority/accountManagement",
          ], resolve),
        meta: {
          title: "账号管理",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/AccountAuthority/RoleManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/AccountAuthority/RoleManagement",
          ], resolve),
        meta: {
          title: "角色管理",
          keepAlive: true,
        },
      },
      {
        path: "/systemManagement/AccountAuthority/jurisdiction",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/AccountAuthority/jurisdiction",
          ], resolve),
        meta: {
          title: "角色分配权限",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/AccountAuthority/jurisdictionUser",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/AccountAuthority/jurisdictionUser",
          ], resolve),
        meta: {
          title: "用户分配权限",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/AdvertisingManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/AdvertisingManagement",
          ], resolve),
        meta: {
          title: "公告管理",
          keepAlive: false,
        },
      },

      {
        //列表页
        path: "/pages/UserAgreement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/serveXieYiGuanLi",
          ], resolve),
        meta: {
          title: "服务协议管理",
          keepAlive: false,
        },
      },

      {
        path: "/systemManagement/advancedSetting/AdvertisingRotation",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/AdvertisingRotation",
          ], resolve),
        meta: {
          title: "轮播图管理",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/MessageManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/MessageManagement",
          ], resolve),
        meta: {
          title: "消息管理",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/TutorialManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/TutorialManagement",
          ], resolve),
        meta: {
          title: "教程管理",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/LogRecord",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/LogRecord",
          ], resolve),
        meta: {
          title: "日志记录",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/OnlineUsers",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/OnlineUsers",
          ], resolve),
        meta: {
          title: "在线用户",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/MenuManagement",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/MenuManagement",
          ], resolve),
        meta: {
          title: "菜单管理",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/dataDictionary",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/dataDictionary",
          ], resolve),
        meta: {
          title: "数据字典",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/systemParameter",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/systemParameter",
          ], resolve),
        meta: {
          title: "系统参数",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/ListPrintFormat",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/ListPrintFormat",
          ], resolve),
        meta: {
          title: "申请打包清单打印格式",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/LabelPrintFormat",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/LabelPrintFormat",
          ], resolve),
        meta: {
          title: "标签打印格式",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/PrintSettings",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/PrintSettings",
          ], resolve),
        meta: {
          title: "打印设置",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/advancedSetting/ExportSettings",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/ExportSettings",
          ], resolve),
        meta: {
          title: "导出设置",
          keepAlive: false,
        },
      },
      // {
      //     path: '/PayableManagement/ChannelOfferWeight',
      //     component: resolve => require(['@/pages/PayableManagement/ChannelOfferWeight'], resolve),
      //     meta: {
      //         title: '报价重量段'
      //     },
      // },
      // {
      //     path: '/PayableManagement/ChannelOfferDiff',
      //     component: resolve => require(['@/pages/PayableManagement/ChannelOfferDiff'], resolve),
      //     meta: {
      //         title: '报价分区'
      //     },
      // },
      {
        path: "/PayableManagement/ChannelOfferCaculation",
        component: (resolve) =>
          require([
            "@/pages/PayableManagement/ChannelOfferCaculation",
          ], resolve),
        meta: {
          title: "报价计算公式",
          keepAlive: false,
        },
      },
      // {
      //     path: '/PayableManagement/ChannelOfferMenu',
      //     component: resolve => require(['@/pages/PayableManagement/ChannelOfferMenu'], resolve),
      //     meta: {
      //         title: '报价目录'
      //     },
      // },
      {
        path: "/PayableManagement/NewChannelAgent",
        component: (resolve) =>
          require(["@/pages/PayableManagement/NewChannelAgent"], resolve),
        meta: {
          title: "新建渠道代理",
          keepAlive: false,
        },
      },
      {
        path: "/MemberManagement/CheckMemberIdList",
        component: (resolve) =>
          require(["@/pages/MemberManagement/CheckMemberIdList"], resolve),
        meta: {
          title: "修改会员号审核列表",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/BasicSettings/printLogo",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/printLogo",
          ], resolve),
        meta: {
          title: "logo配置",
          keepAlive: false,
        },
      },
      {
        path: "/systemManagement/customerService/customerService",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/customerService/customerService",
          ], resolve),
        meta: {
          title: "客服管理",
          keepAlive: false,
        },
      },
      {
        path: "/pages/IndentOrder/IndentOrderList",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/IndentOrderList",
          ], resolve),
        meta: {
          title: "代购订单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/AgencyPurchaseServiceFee",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/AgencyPurchaseServiceFee",
          ], resolve),
        meta: {
          title: "代购服务费设置",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/addIndentOrder",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/addIndentOrder",
          ], resolve),
        meta: {
          title: "新增代购单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/EditaddIndentOrder",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/EditaddIndentOrder",
          ], resolve),
        meta: {
          title: "修改代购单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/addPurchaseOrder",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/addPurchaseOrder",
          ], resolve),
        meta: {
          title: "新增采购单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/EditPurchaseOrder",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/EditPurchaseOrder",
          ], resolve),
        meta: {
          title: "修改采购单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/PurchaseOrderList",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/PurchaseOrderList",
          ], resolve),
        meta: {
          title: "采购订单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/IndentOrderDetail",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/IndentOrderDetail",
          ], resolve),
        meta: {
          title: "代购单详情",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/ExpertPurchase",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/ExpertPurchase",
          ], resolve),
        meta: {
          title: "专家购申请",
          keepAlive: true,
        },
      },
      {
        path: "/pages/IndentOrder/ExpertPurchaseDetails",
        component: (resolve) =>
          require([
            "@/pages/IndentOrder/ExpertPurchaseDetails",
          ], resolve),
        meta: {
          title: "跟进专家购工单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/SystemArticle",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/ArticleManagement/SystemArticle",
          ], resolve),
        meta: {
          title: "系统文章",
          keepAlive: false,
        },
      },
      {
        path: "/pages/IntroductionArticle",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/ArticleManagement/IntroductionArticle",
          ], resolve),
        meta: {
          title: "攻略文章",
          keepAlive: false,
        },
      },
      {
        path: "/pages/AddSystemArticle",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/ArticleManagement/AddSystemArticle",
          ], resolve),
        meta: {
          title: "新建系统文章",
          keepAlive: true,
        },
      },
      {
        path: "/pages/EditSystemArticle",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/ArticleManagement/EditSystemArticle",
          ], resolve),
        meta: {
          title: "编辑系统文章",
          keepAlive: true,
        },
      },
      {
        path: "/pages/AddIntroductionArticle",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/ArticleManagement/AddIntroductionArticle",
          ], resolve),
        meta: {
          title: "新增攻略文章",
          keepAlive: true,
        },
      },
      {
        path: "/pages/EditIntroductionArticle",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/ArticleManagement/EditIntroductionArticle",
          ], resolve),
        meta: {
          title: "编辑攻略文章",
          keepAlive: true,
        },
      },

      // 服务协议新增页面
      {
        //列表页
        path: "/pages/addServeXieYiGuanLi",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/addServeXieYiGuanLi",
          ], resolve),
        meta: {
          title: "服务协议",
          keepAlive: false,
        },
      },
      {
        path: "/pages/xieYiGuanLiDetail",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/advancedSetting/xieYiGuanLiDetail",
          ], resolve),
        meta: {
          title: "服务协议详情",
          keepAlive: false,
        },
      },
      {
        path: "/pages/PackageList",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/PackageList",
          ], resolve),
        meta: {
          title: "合包列表",
          keepAlive: true,
        },
      },
      {
        path: "/pages/PackageListAdd",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/PackageListAdd.vue",
          ], resolve),
        meta: {
          title: "新建合包",
          keepAlive: true,
        },
      },
      {
        path: "/pages/PackageListEdit",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/PackageListEdit.vue",
          ], resolve),
        meta: {
          title: "修改合包",
          keepAlive: true,
        },
      },
      {
        path: "/pages/BillPackStockRecord",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/BillPackStockRecord.vue",
          ], resolve),
        meta: {
          title: "出入库日志",
          keepAlive: true,
        },
      },
      {
        path: "/pages/ApplicationPackag_new",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/ApplicationPackag_new/ApplicationPackag_new.vue",
          ], resolve),
        meta: {
          title: "申请打包单",
          keepAlive: true,
        },
      },
      {
        path: "/pages/PackagingDetails_new",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/ApplicationPackag_new/PackagingDetails_new.vue",
          ], resolve),
        meta: {
          title: "打包单详情",
          keepAlive: true,
        },
      },
      {
        path: "/pages/PackagingDetails_new_daHuo",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/ApplicationPackag_new/PackagingDetails_new_daHuo.vue",
          ], resolve),
        meta: {
          title: "大货单详情",
          keepAlive: true,
        },
      },
      {
        path: "/pages/customerServiceTrack",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/customerServiceTrack/customerServiceTrack.vue",
          ], resolve),
        meta: {
          title: "客服跟踪",
          keepAlive: true,
        },
      },
      {
        path: "/pages/ExWarehouse/daHuoList",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/daHuoList/daHuoList.vue",
          ], resolve),
        meta: {
          title: "大货列表",
          keepAlive: true,
        },
      },
      {
        path: "/pages/ExWarehouse/addDaHuoB2BCK",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/daHuoList/addDaHuoB2BCK.vue",
          ], resolve),
        meta: {
          title: "B2B待出库",
          keepAlive: true,
        },
      },
      {
        path: "/pages/ExWarehouse/addDaHuo",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/daHuoList/addDaHuo.vue",
          ], resolve),
        meta: {
          title: "添加大货",
          keepAlive: true,
        },
      },
      {
        path: "/pages/ExWarehouse/editDaHuo",
        component: (resolve) =>
          require([
            "@/pages/BusinessManagement/ExWarehouse/daHuoList/editDaHuo.vue",
          ], resolve),
        meta: {
          title: "修改大货",
          keepAlive: true,
        },
      },
      {
        path: "/pages/systemManagement/KeywordManage",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/userManage/KeywordManage.vue",
          ], resolve),
        meta: {
          title: "代购关键字",
          keepAlive: true,
        },
      },
      {
        path: "/pages/systemManagement/DataDictionary",
        component: (resolve) => require(["@/pages/systemManagement/DataDictionary"], resolve),
        meta: {
          title: "数据字典",
          keepAlive: true,
        },
      },
      {
        path: "/pages/userManage/indexGoods",
        component: (resolve) => require(["@/pages/systemManagement/userManage/indexGoods"], resolve),
        meta: {
          title: "首页商品管理",
          keepAlive: true,
        },
      },
      {
        path: "/pages/userManage/colorSetting",
        component: (resolve) => require(["@/pages/systemManagement/userManage/colorSetting"], resolve),
        meta: {
          title: "会员端颜色设置",
          keepAlive: true,
        },
      },
      {
        path: "/pages/userManage/gongGeSetting",
        component: (resolve) => require(["@/pages/systemManagement/userManage/gongGeSetting"], resolve),
        meta: {
          title: "首页宫格设置",
          keepAlive: true,
        },
      },
      {
        path: "/pages/customerService/DMCustomerService",
        component: (resolve) => require(["@/pages/systemManagement/customerService/DMCustomerService"], resolve),
        meta: {
          title: "跟单客服",
          keepAlive: true,
        },
      },
      {
        path: "/pages/test",
        component: (resolve) => require(["@/pages/test"], resolve),
        meta: {
          title: "语言包表格转换",
          keepAlive: true,
        },
      },
      {
        path: "/SystemSetting/LogoSettings",
        component: (resolve) =>
          require([
            "@/pages/systemManagement/BasicSettings/LogoSettings",
          ], resolve),
        meta: {
          title: "logo设置",
          keepAlive: true,
        },
      },
      {
        path: "/DynamicList/CouponManagement",
        component: (resolve) =>
          require([
            "@/pages/DynamicList/CouponManagement",
          ], resolve),
        meta: {
          title: "优惠券列表",
          keepAlive: true,
        },
      },
      {
        path: "/DynamicList/CouponHasClaimed",
        component: (resolve) =>
          require([
            "@/pages/DynamicList/CouponHasClaimed",
          ], resolve),
        meta: {
          title: "优惠券领取列表",
          keepAlive: true,
        },
      },
    ],
  },
  ],
});
// router.beforeEach((to, from, next) => {
//   // 这一步是为了清空无用的页面缓存
//   let toDepth = to.path.split("/").length;
//   let fromDepth = from.path.split("/").length;
//   if (toDepth < fromDepth) {
//     from.meta.keepAlive = false;
//     to.meta.keepAlive = true;
//   }

//   next();

// })

export default router;