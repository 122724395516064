import axios from 'axios';
import {
    axios_requer,
    axios_file
} from './http_axios'


const apiList = {
    api_upload: '/base/saas/v1/file/upload', //上传文件
    api_oauthAdmin: '/v1/admin/oauth/oauthAdmin', //登录
    api_pushFindFieldSort: '/v1/admin/customized/pushFindFieldSort', //保存用户查询字段排序规则
    api_getFindFieldSort: '/v1/admin/customized/getFindFieldSort', //获取用户查询字段排序规则(弃用)
    api_getGroupFindFieldSort: '/v1/admin/customized/getGroupFindFieldSort', //获取用户查询字段排序规则
    api_packageList: '/v1/admin/pack/packageList', //包裹综合查询
    api_getAdminMenus: '/v1/admin/oauth/getAdminMenus', //获取左侧菜单数组
    api_getUserExportTemplate: '/v1/admin/poi/getUserExportTemplate', //获取用户导出模板 ===>get
    api_exportTemplateDownload: '/v1/admin/poi/exportTemplateDownload', //下载用户导出模板  ==>post / json
    api_saveExportTemplate: '/v1/admin/poi/saveExportTemplate', //保存用户导出模板  ==>post
    api_templateDataExport: '/v1/admin/poi/templateDataExport', //模板数据导出  ==>post
    api_courierNumberList: '/v1/admin/pack/courierNumberList', //查询快递单号列表  ==>get
    api_packageDetails: '/v1/admin/pack/packageDetails', //包裹详情  ==>get
    api_addMenu: '/v1/devops/menu/addMenu', //新增菜单  ==>post
    api_getSubMenus: '/v1/devops/menu/getSubMenus', //新增菜单  ==>post (运维)
    api_updateMenu: '/v1/devops/menu/updateMenu', //跟新菜单  ==>post (运维)
    api_login: '/v1/devops/auth/login', //登录(运维系统登录)

    api_unclaimedPackageList: '/v1/admin/pack/claim/unclaimedPackageList', //未认领包裹 ==>get
    api_exceptionPackageList: '/v1/admin/pack/exception/exceptionPackageList', //异常包裹  ==>get
    api_questionFeedbacks: '/v1/admin/pack/question/questionFeedbacks', //异常问题
    api_signforList: '/v1/admin/pack/signfor/signforList', //到库签收列表  ==>get
    api_storageCargoareaFrameList: '/admin/v1/storage/storageCargoareaFrameList', //货架列表
    api_storageCargoareaList: '/admin/v1/storage/storageCargoareaList', //货区列表
    //hw

    api_otherFinanceOutList: '/v1/admin/finance/out/otherFinanceOutList', //其他支出和代理加收列表
    api_itemServiceListAll: '/v1/admin/expenditure/itemServiceListAll', //费项列表不分页
    api_financeDocumentstatusChooseList: '/v1/admin/finance/other/financeDocumentstatusChooseList', //其他单据类型选择列表
    api_addAgentAdditional: '/v1/admin/finance/additional/addAgentAdditional', //新增代理加收 ==post
    api_addPackage: '/v1/admin/pack/addPackage', //添加包裹  ==>post json
    api_getStorageList: '/admin/v1/storage/getStorageList', //货区仓库下拉列表 ==>GET
    api_getMemberByMemberId: '/v1/admin/pack/getMemberByMemberId', //根据会员号获取会员信息 ==GET
    api_signPackage: '/v1/admin/pack/signfor/signPackage', //到库签收 ==post
    api_ruKuDetail: '/v1/admin/pack/instock/ruKuDetail', //入库确认 ==>GTE
    api_claimPackage: '/v1/admin/pack/claim/claimPackage', //认领确认 ==>post
    api_ruKuPackage: '/v1/admin/pack/instock/ruKuPackage', //入库  ==>post
    api_getPackageUpframeList: '/v1/admin/pack/upframe/getPackageUpframeList', //包裹上架  ==>GET
    api_upFrame: '/v1/admin/pack/upframe/upFrame', //上架确认  ==>post
    api_getStorageCargoareaChooseList: '/admin/v1/storage/getStorageCargoareaChooseList', //获取货区选择列表  ==>GET
    api_getStorageCargoareaFrameChooseList: '/admin/v1/storage/getStorageCargoareaFrameChooseList', //获取货架选择列表  ==>GET
    api_outStockList: '/v1/admin/pack/instock/outStockList', //出库列表  ==>GET
    api_outStock: '/v1/admin/pack/instock/outStock', //出库确认  ==>post
    api_cancelOutStock: '/v1/admin/pack/instock/cancelOutStock', //取消出庫  ==>post
    api_financeOutList: '/v1/admin/finance/out/financeOutList', //应付管理
    api_financeOutWriteoffList: '/v1/admin/finance/out/financeOutWriteoffList', //应付核销
    api_addAdverInfo: '/v1/admin/news/addNewsAdvert', // 新增广告通知消息
    api_modifyAdverInfo: '/v1/admin/news/updateNewsAdvert', // 修改广告通知消息
    api_addAdverBanner: '/v1/admin/news/addNewsBanner', // 新增广告banner
    api_modifyAdverBanner: '/v1/admin/news/updateNewsBanner', // 修改广告banner
    api_addItemCategory: '/v1/admin/expenditure/addItemCategory', // 新增费项分类
    api_addItemService: '/v1/admin/expenditure/addItemService', // 新增费项
    api_updateItemCategory: '/v1/admin/expenditure/updateItemCategor', // 修改费项分类
    api_updateItemService: '/v1/admin/expenditure/updateItemService', // 修改费项
    api_getItemCategories: '/v1/admin/expenditure/itemCategorChoose', // 费项类别选择列表
    api_getItemServiceList: '/v1/admin/expenditure/itemServiceList', // 获取费项列表
    api_getItemCategoryList: '/v1/admin/expenditure/itemCategoryList', // 获取费项分类列表
    api_addStorageCargoarea: '/admin/v1/storage/addStorageCargoarea', // 新增货区
    api_addStorageV1: '/admin/v1/storage/addStorageV1', // 新增仓库地址
    api_addstorageCargoareaFrame: '/admin/v1/storage/addstorageCargoareaFrame', // 新增货架
    api_delStorageV1: '/admin/v1/storage/delStorageV1', // 删除仓库地址
    api_getStorageCargoareaList: '/admin/v1/storage/storageCargoareaList', // 获取货区列表
    api_getStorageCargoareaFrameList: '/admin/v1/storage/storageCargoareaFrameList', // 获取货架列表
    api_getStorageListPage: '/admin/v1/storage/getStorageListPage', // 获取仓库列表
    api_setDefaultStorage: '/admin/v1/storage/setDefaultStorage', // 设置仓库地址为默认
    api_updateStorageV1: '/admin/v1/storage/updateStorageV1', // 修改仓库地址
    api_advertList: '/v1/admin/news/advertList', // 广告列表
    api_bannerList: '/v1/admin/news/bannerList', // 广告banner列表
    api_delAdvert: '/v1/admin/news/delAdvert', // 删除广告
    api_delAdvBanner: '/v1/admin/news/delBanner', // 删除广告banner

    // 渠道
    api_addOrUpdateChannel: '/v1/admin/channel/addOrUpdateChannel', // 新建或修改渠道
    api_getChannelList: '/v1/admin/channel/getChannelList', // 渠道列表
    api_delChannel: '/v1/admin/channel/delChannel', // 删除渠道
    api_addOrUpdateTransportMethod: '/v1/admin/channel/addOrUpdateTransportMethod', // 新建或修改运输方式
    api_getTransportMethodList: '/v1/admin/channel/getTransportMethodList', // 运输方式列表
    api_addOrUpdateChannelItemCategory: '/v1/admin/channel/addOrUpdateItemCategory', // 新建或修改渠道物品类别
    api_getChannelItemCategoryList: '/v1/admin/channel/getItemCategoryList', // 渠道物品类别列表
    api_addOrUpdateChannelOffer: '/v1/admin/channel/addOrUpdateOffer', // 新建或修改渠道报价
    api_getChannelOfferList: '/v1/admin/channel/getOfferList', // 渠道报价列表
    api_delChannelOffer: '/v1/admin/channel/delOffer', // 删除渠道报价
    api_addOrUpdateChannelOfferPartition: '/v1/admin/channel/addOrUpdateOfferPartition', // 新建或修改渠道报价分区
    api_getChannelOfferPartitionList: '/v1/admin/channel/getOfferPartitionList', // 获取渠道报价分区列表
    api_delChannelOfferPartition: '/v1/admin/channel/delOfferPartition', // 删除渠道报价分区
    api_addOrUpdateDivisor: '/v1/admin/channel/addOrUpdateDivisor', // 新建或修改才积除
    api_getDivisorList: '/v1/admin/channel/getDivisorList', // 才积除列表
    api_addOrUpdateOffeWeightr: '/v1/admin/channel/addOrUpdateOffeWeightr', // 新建或修改渠道报价重量段
    api_getOfferWeightList: '/v1/admin/channel/getOfferWeightList', // 渠道报价重量段列表
    api_delOfferWeight: '/v1/admin/channel/delOfferWeight', // 删除渠道报价重量段
    api_addOrUpdateOfferList: '/v1/admin/channel/addOrUpdateOfferList', // 添加-修改渠道报价目录
    api_getOfferListList: '/v1/admin/channel/getOfferListList', // 渠道报价目录列表
    api_delOfferList: '/v1/admin/channel/delOfferList', // 删除渠道报价目录
    api_addOrUpdateOfferFormula: '/v1/admin/channel/addOrUpdateOfferFormula', // 添加-修改渠道报价金额计算公式
    api_getOfferFormulaList: '/v1/admin/channel/getOfferFormulaList', // 渠道报价金额计算公式列表
    api_delOfferFormula: '/v1/admin/channel/delOfferFormula', // 删除渠道报价金额计算公式
    api_getFormulaBillingWeithtAllList: '/common/v1/formula/getFormulaBillingWeithtAllList', // 获取计费重量公式列表(下拉列表)
    api_getFormulaBillingWeithtCarryAllList: '/common/v1/formula/getFormulaBillingWeithtCarryAllList', // 获取计费重量进位规则列表(下拉列表)
    //国家
    api_getAreaContryList: '/v1/admin/area/getAreaContryList', // 国家地区列表
    api_addOrUpdateAreaContry: '/v1/admin/area/addOrUpdateAreaContry', // 添加-修改国家地区

}

// axios_requer(path, params, method, headerType ,'是否显示加载中','是否提示报错信息')

let hasSing = true; //需要签名的加上这个
let method = "GET"; //需要GET请求的加上这个
let headerType = "json"; //需要JSON格式的加上这个

const Api = {
    // 公共部分
    // 获取租户logo图片
    async getSystemVI(data) {
        return await axios_requer(
            '/admin/systemvi/getSystemVI',
            data,
        ).then(res => res);
    },
    // 保存logo图片
    async updateSystemVI(data) {
        return await axios_requer(
            '/admin/systemvi/updateSystemVI',
            data,
            'post'
        ).then(res => res);
    },

    // 上传文件
    async uploadFile(data) {
        return await axios_file(apiList.api_upload, data, 'post').then(res => res);
    },
    // 运维登录接口
    async yw_login(data) {
        return await axios_requer(apiList.api_login, data, 'post').then(res => res);
    },
    // 获取登录方式
    async getLoginMethod() {
        return await axios_requer('/v1/common/loginpage/getLoginMethod').then(res => res);
    },
    // 验证码登录
    async oauthAdminByCode(data) {
        return await axios_requer('/v1/admin/oauth/oauthAdminByCode', data, 'post').then(res => res);
    },
    // 发送短信获取验证码
    async numberSplit(data) {
        return await axios_requer('/common/v1/alisms/numberSplit', data).then(res => res);
    },
    // 校验手机号是否已申请
    async isApplyForTrial(data) {
        return await axios_requer('/v1/devops/customer/isApplyForTrial', data, 'post').then(res => res);
    },
    // 获取租户应用
    async getTenantApp(data) {
        return await axios_requer('/common/v1/tenant/getTenantApp', data).then(res => res);
    },

    // 新建菜单(运维)
    async addMeun(data) {
        return await axios_requer(apiList.api_addMenu, data, 'post').then(res => res);
    },
    // 获取菜单(运维)
    async getSubMenus(data) {
        return await axios_requer(apiList.api_getSubMenus, data).then(res => res);
    },
    // 更新菜单(运维)
    async updateMenu(data) {
        return await axios_requer(apiList.api_updateMenu, data, 'post').then(res => res);
    },
    // 登录接口
    async oauthAdmin(data) {
        return await axios_requer(apiList.api_oauthAdmin, data, 'post').then(res => res);
    },
    // 公用(查询状态列表)
    // 查询条件 ===>包裹状态:package_v1.package_status
    // 查询条件 ===>拒收单拒收状态:bill_rejection_v1.handle_status
    // 查询条件 ===>验货单验货状态:bill_inspection_v1.check_status
    // 查询条件 ===>审批类型状态:finance_in_record_v1.approve_status
    //...........
    async getStatusValList(data) {
        return await axios_requer('/common/v1/dict/status/getStatusValList', data).then(res => res);
    },
    // 保存用户查询字段排序规则
    async pushFindFieldSort(data) {
        return await axios_requer(apiList.api_pushFindFieldSort, data, 'post').then(res => res);
    },
    // 获取用户查询字段排序规则
    async getGroupFindFieldSort(data) {
        return await axios_requer(apiList.api_getGroupFindFieldSort, data).then(res => res);
    },
    // 获取用户导出模板
    async getUserExportTemplate(data) {
        return await axios_requer(apiList.api_getUserExportTemplate, data).then(res => res);
    },
    // 下载用户导出模板   ===>json
    async exportTemplateDownload(data) {
        return await axios_requer(apiList.api_exportTemplateDownload, data, 'post', 'json').then(res => res);
    },
    // 保存用户导出模板
    async saveExportTemplate(data) {
        return await axios_requer(apiList.api_saveExportTemplate, data, 'post').then(res => res);
    },
    // 模板数据导出
    async templateDataExport(data) {
        return await axios_requer(apiList.api_templateDataExport, data, 'post').then(res => res);
    },
    //获取左侧菜单数组
    async getAdminMenus(data) {
        return await axios_requer(apiList.api_getAdminMenus, data).then(res => res)
    },
    //自定义标题动态导出(默认模板)
    async dynamicExport(data) {
        return await axios_requer('/v1/admin/poi/dynamicExport', data, 'post', 'json').then(res => res)
    },
    //hw***********************
    //渠道类别下拉
    async channelChooseList(data) {
        return await axios_requer('/v1/admin/channel/channelChooseList', data).then(res => res)
    },
    //计费重量进位规则
    async addBillingWeightCarry(data) {
        return await axios_requer('/v1/devops/formula/addBillingWeightCarry', data, 'post').then(res => res)
    },
    //计费重量计算规则
    async addBillingWeight(data) {
        return await axios_requer('/v1/devops/formula/addBillingWeight', data, 'post').then(res => res)
    },
    //代理加收审核
    async agentAdditionalExamine(data) {
        return await axios_requer('/v1/admin/finance/additional/agentAdditionalExamine', data, 'post', 'json').then(res => res)
    },
    //代理加收核销
    async agentAdditionalWriteOff(data) {
        return await axios_requer('/v1/admin/finance/additional/agentAdditionalWriteOff', data, 'post', 'json').then(res => res)
    },
    //其他应付审核
    async otherFinanceOutExamine(data) {
        return await axios_requer('/v1/admin/finance/other/otherFinanceOutExamine', data, 'post', 'json').then(res => res)
    },
    //其他应付核销
    async otherFinanceOutWriteOff(data) {
        return await axios_requer('/v1/admin/finance/other/otherFinanceOutWriteOff', data, 'post', 'json').then(res => res)
    },
    //资金流水账列表
    async journalAccountList(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/journalAccountList', data, 'post', 'json').then(res => res)
    },
    //资金余额表
    async accountBalanceList(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/accountBalanceList', data, 'post', 'json').then(res => res)
    },
    //应付收款核销返核销
    async financeOutWriteoff(data) {
        return await axios_requer('/v1/admin/finance/out/financeOutWriteoff', data, 'post', 'json').then(res => res)
    },
    //应付管理审核和返审核
    async financeOutExamine(data) {
        return await axios_requer('/v1/admin/finance/out/financeOutExamine', data, 'post', 'json').then(res => res)
    },
    //收支详情
    async otherFinanceOutDetail(data) {
        return await axios_requer('/v1/admin/finance/out/otherFinanceOutDetail', data).then(res => res)
    },
    //收支申请
    async financeInAndOutApply(data) {
        return await axios_requer('/v1/admin/finance/other/financeInAndOutApply', data, 'post').then(res => res)
    },
    //根据类别和费项类型获取费项
    async getCategoryServiceList(data) {
        return await axios_requer('/v1/admin/expenditure/getCategoryServiceList', data).then(res => res)
    },
    //修改代理加收
    async updateAgentAdditional(data) {
        return await axios_requer('/v1/admin/finance/additional/updateAgentAdditional', data, 'post').then(res => res)
    },
    //代理加收详情
    async agentAdditionalDetail(data) {
        return await axios_requer('/v1/admin/finance/additional/agentAdditionalDetail', data).then(res => res)
    },
    //费项列表
    async itemServiceListAll(data) {
        return await axios_requer(apiList.api_itemServiceListAll, data).then(res => res)
    },
    //其他单据类型选择列表
    async financeDocumentstatusChooseList(data) {
        return await axios_requer(apiList.api_financeDocumentstatusChooseList, data).then(res => res)
    },
    //新增代理加收
    async addAgentAdditional(data) {
        return await axios_requer(apiList.api_addAgentAdditional, data, 'post').then(res => res)
    },

    //  新增客户加收
    async addCustomerAdditional(data) {
        return await axios_requer('/v1/admin/finance/additional/addCustomerAdditional', data, 'post').then(res => res)
    },

    //其他支出和代理加收列表
    async otherFinanceOutList(data) {
        return await axios_requer(apiList.api_otherFinanceOutList, data, 'post', 'json').then(res => res)
    },
    //修改渠道代理
    async updateChannelAgent(data) {
        return await axios_requer('/v1/admin/channelAgent/updateChannelAgent', data, 'post', 'json').then(res => res)
    },
    //获取渠道详情
    async channelAgentDetail(data) {
        return await axios_requer('/v1/admin/channelAgent/channelAgentDetail', data).then(res => res)
    },
    //审核类型
    async channelAgentExamine(data) {
        return await axios_requer('/v1/admin/channelAgent/channelAgentExamine', data, 'post').then(res => res)
    },
    //新增渠道代理
    async addChannelAgent(data) {
        return await axios_requer('/v1/admin/channelAgent/addChannelAgent', data, 'post', 'json').then(res => res);
    },
    //应付余额代表
    async agentBalanceList(data) {
        return await axios_requer('/v1/admin/finance/out/agentBalanceList', data).then(res => res);
    },
    // //渠道代理列表
    // async channelAgentList(data) {
    //     return await axios_requer('/v1/admin/channelAgent/channelAgentList', data).then(res => res)
    // },
    //添加包裹
    async addPackage(data) {
        return await axios_requer(apiList.api_addPackage, data, 'post', 'json').then(res => res);
    },
    //货区仓库下拉列表
    async getStorageList() {
        return await axios_requer(apiList.api_getStorageList, 'GET').then(res => res);
    },
    //到库签收
    async signPackage(data) {
        return await axios_requer(apiList.api_signPackage, data, 'post').then(res => res);
    },
    //  修改签收件数
    async updateSignPackageCount(data) {
        return await axios_requer('/v1/admin/pack/signfor/updateSignPackageCount', data, 'post').then(res => res);
    },
    //入库确认
    async ruKuDetail(data) {
        return await axios_requer(apiList.api_ruKuDetail, data, 'GET').then(res => res);
    },
    //货架列表
    async storageCargoareaFrameList(data) {
        return await axios_requer(apiList.api_storageCargoareaFrameList, data, 'GET').then(res => res);
    },
    //获取货架列表
    async getStorageCargoareaFrameList(data) {
        return await axios_requer(apiList.api_getStorageCargoareaFrameList, data).then(res => res);
    },
    //获取打印货架列表
    async printFrame(data) {
        return await axios_requer('/admin/v1/storage/printFrame', data, 'pist', 'json').then(res => res);
    },
    //认领确认
    async claimPackage(data) {
        return await axios_requer(apiList.api_claimPackage, data, 'post').then(res => res);
    },
    //入库
    async ruKuPackage(data) {
        return await axios_requer(apiList.api_ruKuPackage, data, 'post', 'json').then(res => res);
    },
    //包裹上架列表
    async getPackageUpframeList(data) {
        return await axios_requer(apiList.api_getPackageUpframeList, data, 'GET').then(res => res);
    },
    //上架确认
    async upFrame(data) {
        return await axios_requer(apiList.api_upFrame, data, 'post', 'json').then(res => res);
    },
    //获取货区选择列表
    async getStorageCargoareaChooseList(data) {
        return await axios_requer(apiList.api_getStorageCargoareaChooseList, data, 'GET').then(res => res);
    },
    //获取货架选择列表
    async getStorageCargoareaFrameChooseList(data) {
        return await axios_requer(apiList.api_getStorageCargoareaFrameChooseList, data, 'GET').then(res => res);
    },

    //      -------  出库确认模块 --------
    //出库列表
    async outStockList(data) {
        return await axios_requer(apiList.api_outStockList, data, 'GET').then(res => res);
    },
    //出库确认
    async outStock(data) {
        return await axios_requer(apiList.api_outStock, data, 'post', 'json').then(res => res);
    },
    //取消出庫
    async cancelOutStock(data) {
        return await axios_requer(apiList.api_cancelOutStock, data, 'post', 'json').then(res => res);
    },

    //          ------- 拣货下架  ------------
    //  拣货下架列表
    async downframeList(data) {
        return await axios_requer('/v1/admin/pack/downframe/downframeList', data).then(res => res);
    },
    //  拣货下架确认
    async downframeConfirm(data) {
        return await axios_requer('/v1/admin/pack/downframe/downframeConfirm', data, 'post', 'json').then(res => res);
    },
    //  拣货下架详情
    async getDownframeDetail(data) {
        return await axios_requer('/v1/admin/pack/downframe/getDownframeDetail', data).then(res => res);
    },
    //      -------  包裹管理部分 --------
    // 包裹综合查询
    async packageList(data) {
        return await axios_requer(apiList.api_packageList, data, 'post', 'json').then(res => res);
    },
    // 作废（删除）
    async delPackage(data) {
        return await axios_requer('/v1/admin/pack/delPackage', data, 'post').then(res => res);
    },
    // 根据会员id获取会员信息
    async getMemberByMemberId(data) {
        return await axios_requer('/v1/admin/pack/getMemberByMemberId', data).then(res => res);
    },
    // 查询快递单号列表
    async courierNumberList(data) {
        return await axios_requer(apiList.api_courierNumberList, data, 'post', 'json').then(res => res);
    },
    // 包裹详情
    async packageDetails(data) {
        return await axios_requer(apiList.api_packageDetails, data).then(res => res);
    },
    // 包裹物品(包裹明细)
    async packageItems(data) {
        return await axios_requer('/v1/admin/pack/packageItems', data).then(res => res);
    },
    // 操作日志
    async packageLogOperates(data) {
        return await axios_requer('/v1/admin/pack/packageLogOperates', data).then(res => res);
    },
    // 获取包裹详情-拒收
    async getPackageRejectionDetail(data) {
        return await axios_requer('/v1/admin/pack/getPackageRejectionDetail', data).then(res => res);
    },
    // 获取包裹详情-拒收附件
    async getPackageRejectionPic(data) {
        return await axios_requer('/v1/admin/pack/getPackageRejectionPic', data).then(res => res);
    },
    //  获取包裹详情-验货
    async getPackageInspectionDetail(data) {
        return await axios_requer('/v1/admin/pack/getPackageInspectionDetail', data).then(res => res);
    },
    // 获取包裹详情-验货附件
    async getPackageInspectionPic(data) {
        return await axios_requer('/v1/admin/pack/getPackageInspectionPic', data).then(res => res);
    },
    //  获取包裹详情-异常
    async getPackageExceptionDetail(data) {
        return await axios_requer('/v1/admin/pack/getPackageExceptionDetail', data).then(res => res);
    },
    // 获取包裹详情-异常附件
    async getPackageExceptionPic(data) {
        return await axios_requer('/v1/admin/pack/getPackageExceptionPic', data).then(res => res);
    },
    //  获取包裹详情-应收明细
    async getPackageFinanceInDetail(data) {
        return await axios_requer('/v1/admin/pack/getPackageFinanceInDetail', data).then(res => res);
    },
    //  快递物流信息查询(查询轨迹)
    async expressQueryForKD100(data) {
        return await axios_requer('/v1/admin/pack/expressQueryForKD100', data).then(res => res);
    },

    // 获取包裹详情(修改包裹页)
    async getPackageDetail(data) {
        return await axios_requer('/v1/admin/pack/getPackageDetail', data).then(res => res);
    },

    // 根据入库时间获取入库单信息
    async getIntoStorageBillByDate(data) {
        return await axios_requer('/v1/admin/pack/getIntoStorageBillByDate', data).then(res => res);
    },
    // 根据出库时间获取出库单信息
    async getOutStorageBillByDate(data) {
        return await axios_requer('/v1/admin/pack/getOutStorageBillByDate', data).then(res => res);
    },
    // 根据打包时间获取仓库打包单信息
    async getStorepackBillByDate(data) {
        return await axios_requer('/v1/admin/pack/getStorepackBillByDate', data).then(res => res);
    },
    // 根据拒收状态获取拒收单信息
    async getRejectionBillForPackage(data) {
        return await axios_requer('/v1/admin/pack/getRejectionBillForPackage', data).then(res => res);
    },
    // 打包批次号-目的地-渠道代理获取申请打包单信息
    async getPackPackageBillForPackage(data) {
        return await axios_requer('/v1/admin/pack/getPackPackageBillForPackage', data).then(res => res);
    },
    // 查询快递单号下拉列表
    async getExpressNumData(data) {
        return await axios_requer('/v1/admin/pack/courierNumberDownList', data).then(res => res);
    },

    // **************到库签收部分*********
    // 到库签收列表
    async signforList(data) {
        return await axios_requer(apiList.api_signforList, data).then(res => res);
    },
    // 包裹签收记录
    async signforRecordList(data) {
        return await axios_requer('/v1/admin/pack/signfor/signforRecordList', data, 'post', 'json').then(res => res);
    },
    // 取消签收
    async cancelSign(data) {
        return await axios_requer('/v1/admin/pack/signfor/cancelSign', data, 'post', 'json').then(res => res);
    },
    // **************到库签收部分*********
    //未认领包裹
    async unclaimedPackageList(data) {
        return await axios_requer(apiList.api_unclaimedPackageList, data, 'post', 'json').then(res => res);
    },
    //  认领记录列表
    async claimedPackageRecordList(data) {
        return await axios_requer('/v1/admin/pack/claim/claimedPackageRecordList', data, 'post', 'json').then(res => res);
    },
    //  撤销认领
    async revokeClaimPackage(data) {
        return await axios_requer('/v1/admin/pack/claim/revokeClaimPackage', data, 'post', 'json').then(res => res);
    },
    //异常包裹
    async exceptionPackageList(data) {
        return await axios_requer(apiList.api_exceptionPackageList, data).then(res => res);
    },
    //  添加异常包裹
    async addExceptionPackage(data) {
        return await axios_requer('/v1/admin/pack/exception/addExceptionPackage', data, 'post', 'json').then(res => res);
    },
    //  修改异常包裹
    async updataExceptionPackage(data) {
        return await axios_requer('/v1/admin/pack/exception/updatePackageException', data, 'post', 'json').then(res => res);
    },
    //  异常包裹详情
    async packageExceptionDetail(data) {
        return await axios_requer('/v1/admin/pack/exception/packageExceptionDetail', data).then(res => res);
    },

    //  异常处理详情
    async exceptionHandleDetail(data) {
        return await axios_requer('/v1/admin/pack/exception/exceptionHandleDetail', data).then(res => res);
    },
    //  异常处理
    async exceptionHandle(data) {
        return await axios_requer('/v1/admin/pack/exception/exceptionHandle', data, 'post', 'json').then(res => res);
    },
    //根据快递单号获取包裹详情
    async abnormalPackageDetail(data) {
        return await axios_requer('/v1/admin/pack/exception/packageDetail', data).then(res => res);
    },
    //  异常类型列表
    async abnormalList(data) {
        return await axios_requer('/v1/admin/pack/exception/packageExceptionTypePageList', data).then(res => res);
    },
    //  异常类型下拉列表
    async abnormalSList() {
        return await axios_requer('/v1/admin/pack/exception/packageExceptionTypeList').then(res => res);
    },
    //  异常处理类型下拉列表
    async abnormalDealSList(data) {
        return await axios_requer('/v1/admin/pack/exception/packageExceptionHandleTypeChooseList', data, 'post').then(res => res);
    },
    //  新增异常类型
    async addAbnormal(data) {
        return await axios_requer('/v1/admin/pack/exception/addPackageExceptionType', data, 'post').then(res => res);
    },
    //  删除包裹异常
    async deletePackageException(data) {
        return await axios_requer('/v1/admin/pack/exception/deletePackageException', data, 'post', 'json').then(res => res);
    },
    //  修改异常类型
    async updataAbnormal(data) {
        return await axios_requer('/v1/admin/pack/exception/updatePackageExceptionType', data, 'post').then(res => res);
    },
    //  异常类型详情
    async abnormalTypeDetail(data) {
        return await axios_requer('/v1/admin/pack/exception/packageExceptionTypeDetail', data).then(res => res);
    },
    // **************拒收退货*********

    // 拒收退货列表
    async rejectionList(data) {
        return await axios_requer('/v1/admin/rejection/rejectionList', data).then(res => res);
    },

    // 新建拒签
    async addRefuse(data) {
        return await axios_requer('/v1/admin/rejection/addRefuse', data, 'post').then(res => res);
    },

    // 删除拒签
    async delRefuse(data) {
        return await axios_requer('/v1/admin/rejection/delRefuse', data, 'post', 'json').then(res => res);
    },
    // 拒收详情
    async getRefuseDetail(data) {
        return await axios_requer('/v1/admin/rejection/getRefuseDetail', data).then(res => res);
    },
    // 拒收处理
    async dealRefuse(data) {
        return await axios_requer('/v1/admin/rejection/todoRefuse', data, 'post', 'json').then(res => res);
    },
    // **************验货管理*********

    // 验货管理列表
    async inspectionPackageList(data) {
        return await axios_requer('/v1/admin/pack/inspection/getPackageInspectionList', data, 'post', 'json').then(res => res);
    },

    // 新建验货
    async addInspection(data) {
        return await axios_requer('/v1/admin/pack/inspection/addInspection', data, 'post', 'json').then(res => res);
    },

    // 获取验货详情
    async getInspectionDetail(data) {
        return await axios_requer('/v1/admin/pack/inspection/getInspectionDetail', data).then(res => res);
    },

    // 验货处理
    async dealInspection(data) {
        return await axios_requer('/v1/admin/pack/inspection/submitInspection', data, 'post', 'json').then(res => res);
    },

    // 验货费项
    async getFx(data) {
        return await axios_requer('/v1/admin/expenditure/getItemServiceList', data).then(res => res);
    },

    // 删除验货
    async deleteInspection(data) {
        return await axios_requer('/v1/admin/pack/inspection/deleteInspection', data, 'post', 'json').then(res => res);
    },
    // 修改验货
    async updateInspection(data) {
        return await axios_requer('/v1/admin/pack/inspection/updateInspection', data, 'post', 'json').then(res => res);
    },

    //      --------  仓库库存列表  -------------
    //  仓库库存列表
    async getPackageStorageList(data) {
        return await axios_requer('/v1/admin/pack/storage/getPackageStorageList', data).then(res => res);
    },
    //  包裹移位
    async updatePackageFrame(data) {
        return await axios_requer('/v1/admin/pack/storage/updatePackageFrame', data, 'post', 'json').then(res => res);
    },
    // **************申请打包*********
    //  申请打包列表
    async applyPackage(data) {
        return await axios_requer('/v1/admin/pack/package/getPackPackageList', data, 'post', 'json').then(res => res);
    },
    //  申请打包明细
    async packPackageDescs(data) {
        return await axios_requer('/v1/admin/pack/package/packPackageDescs', data, 'post', 'json').then(res => res);
    },
    //  允许打包
    async allowPackage(data) {
        return await axios_requer('/v1/admin/pack/package/allowApplyPackPackage', data, 'post').then(res => res);
    },
    // 申请打包
    async applyPack(data) {
        return await axios_requer('/v1/admin/pack/package/applyPackPackage', data, 'post', 'json').then(res => res);
    },
    // 暂停打包
    async stopPackage(data) {
        return await axios_requer('/v1/admin/pack/package/suspendApplyPackPackage', data, 'post').then(res => res);
    },
    //  获取打包服务项(包装服务)
    async getPackageServeApply() {
        return await axios_requer('/v1/admin/pack/package/getPackPackageService').then(res => res);
    },

    //  可选打包包裹列表
    async optionalPackList(data) {
        return await axios_requer('/v1/admin/billpack/modify/optionalPackList', data, 'post', 'json').then(res => res);
    },
    //  获取渠道与运输方式下拉菜单
    async channelAndTransportList(data) {
        return await axios_requer('/v1/admin/billpack/modify/channelAndTransportList', data).then(res => res);
    },
    //  修改申请打包单
    async modifyBillPack(data) {
        return await axios_requer('/v1/admin/billpack/modify/modifyBillPack', data, 'post', 'json').then(res => res);
    },



    //异常问题
    async questionFeedbacks(data) {
        return await axios_requer(apiList.api_questionFeedbacks, data).then(res => res);
    },
    //  新建问题
    async addQuestion(data) {
        return await axios_requer('/v1/admin/pack/question/addQuestion', data, 'post', 'json').then(res => res);
    },
    //  修改问题
    async updateQuestion(data) {
        return await axios_requer('/v1/admin/pack/question/updateQuestion', data, 'post', 'json').then(res => res);
    },
    //  问题详情
    async questionDetail(data) {
        return await axios_requer('/v1/admin/pack/question/questionDetail', data).then(res => res);
    },

    //  问题处理详情
    async handleQuestionDetail(data) {
        return await axios_requer('/v1/admin/pack/question/handleQuestionDetail', data).then(res => res);
    },

    //  处理问题
    async handleQuestion(data) {
        return await axios_requer('/v1/admin/pack/question/handleQuestion', data, 'post', 'json').then(res => res);
    },
    //  问题处理类型下拉列表
    async dealQuestionType() {
        return await axios_requer('/v1/admin/pack/question/questionHandleTypeChooseList').then(res => res);
    },
    //  问题处理类型列表
    async questionHandleTypeList(data) {
        return await axios_requer('/v1/admin/pack/question/questionHandleTypeList', data).then(res => res);
    },
    //  新增问题处理类型
    async addQuestionHandleType(data) {
        return await axios_requer('/v1/admin/pack/question/addQuestionHandleType', data, 'post').then(res => res);
    },
    //  修改问题处理类型
    async updateQuestionHandleType(data) {
        return await axios_requer('/v1/admin/pack/question/updateQuestionHandleType', data, 'post').then(res => res);
    },
    //  问题处理类型详情
    async questionHandleTypeDetail(data) {
        return await axios_requer('/v1/admin/pack/question/questionHandleTypeDetail', data).then(res => res);
    },
    //  问题类型下拉列表
    async questionTypeList() {
        return await axios_requer('/v1/admin/pack/question/questionTypeChooseList').then(res => res);
    },
    //  问题类型列表
    async questionTypePage(data) {
        return await axios_requer('/v1/admin/pack/question/questionTypeList', data).then(res => res);
    },
    //  添加问题类型
    async addQuestionType(data) {
        return await axios_requer('/v1/admin/pack/question/addQuestionType', data, 'post').then(res => res);
    },
    //  修改问题类型
    async updateQuestionType(data) {
        return await axios_requer('/v1/admin/pack/question/updateQuestionType', data, 'post').then(res => res);
    },
    //  问题类型详情
    async questionTypeDetail(data) {
        return await axios_requer('/v1/admin/pack/question/questionTypeDetail', data).then(res => res);
    },
    //  -------------- 打包出库  ------------

    //  客户签收列表
    async customerSign(data) {
        return await axios_requer('/v1/admin/customerSignFor/customerSignForList', data).then(res => res);
    },
    //  签收确认
    async signForConfirm(data) {
        return await axios_requer('/v1/admin/customerSignFor/signForConfirm', data, 'post', 'json').then(res => res);
    },
    //  取消签收
    async cancelSignFor(data) {
        return await axios_requer('/v1/admin/customerSignFor/cancelSignFor', data, 'post', 'json').then(res => res);
    },
    // **************广告管理部分*********
    //新增广告通知消息
    async addAdverInfos(data) {
        return await axios_requer(apiList.api_addAdverInfo, data, 'post').then(res => res);
    },
    //新增广告banner
    async addAdverBanner(data) {
        return await axios_requer(apiList.api_addAdverBanner, data, 'post').then(res => res);
    },
    //修改广告通知消息
    async modifyAdverInfos(data) {
        return await axios_requer(apiList.api_modifyAdverInfo, data, 'post').then(res => res);
    },
    //修改广告banner
    async modifyAdverBanner(data) {
        return await axios_requer(apiList.api_modifyAdverBanner, data, 'post').then(res => res);
    },
    // **************费项与服务*********
    //新增费项
    async addItemService(data) {
        return await axios_requer(apiList.api_addItemService, data, 'post').then(res => res);
    },
    //新增费项分类
    async addItemCategory(data) {
        return await axios_requer(apiList.api_addItemCategory, data, 'post').then(res => res);
    },
    //修改费项
    async modifyItemService(data) {
        return await axios_requer(apiList.api_updateItemService, data, 'post').then(res => res);
    },
    //修改费项分类
    async modifyItemCategory(data) {
        return await axios_requer(apiList.api_updateItemCategory, data, 'post').then(res => res);
    },
    //获取费项分类类别
    async getItemCategories() {
        return await axios_requer(apiList.api_getItemCategories).then(res => res);
    },
    //获取费项分类列表
    async getItemCategoryList(data) {
        return await axios_requer(apiList.api_getItemCategoryList, data).then(res => res);
    },

    //获取费项列表
    async getItemServiceList(data) {
        return await axios_requer(apiList.api_getItemServiceList, data).then(res => res);
    },
    //新增货区
    async addStorageCargoarea(data) {
        return await axios_requer(apiList.api_addStorageCargoarea, data, 'post').then(res => res);
    },
    //修改货区
    async updateStorageCargoarea(data) {
        return await axios_requer('/admin/v1/storage/updateStorageCargoarea', data, 'post').then(res => res);
    },
    //新增仓库地址
    async addStorageV1(data) {
        return await axios_requer(apiList.api_addStorageV1, data, 'post').then(res => res);
    },
    //新增货架
    async addstorageCargoareaFrame(data) {
        return await axios_requer(apiList.api_addstorageCargoareaFrame, data, 'post').then(res => res);
    },
    //删除仓库地址
    async delStorageV1(data) {
        return await axios_requer(apiList.api_delStorageV1, data, 'post').then(res => res);
    },
    // //获取货区列表
    // async getStorageCargoareaList(data) {
    //     return await axios_requer(apiList.api_getStorageCargoareaList, data).then(res => res);
    // },
    //货区列表
    async storageCargoareaList(data) {
        return await axios_requer(apiList.api_storageCargoareaList, data).then(res => res);
    },

    //获取仓库列表
    async getStorageListPage(data) {
        return await axios_requer(apiList.api_getStorageListPage, data).then(res => res);
    },
    //设置仓库地址为默认
    async setDefaultStorage(data) {
        return await axios_requer(apiList.api_setDefaultStorage, data, 'post').then(res => res);
    },
    //修改仓库地址
    async updateStorageV1(data) {
        return await axios_requer(apiList.api_updateStorageV1, data, 'post').then(res => res);
    },
    //包裹入库
    async instockList(data) {
        return await axios_requer('/v1/admin/pack/instock/instockList', data, 'post', 'json').then(res => res);
    },
    //获取广告列表
    async getAdvertList(data) {
        return await axios_requer(apiList.api_advertList, data, 'GET').then(res => res);
    },
    //获取广告Banner列表
    async getAdvBannerList(data) {
        return await axios_requer(apiList.api_bannerList, data, 'GET').then(res => res);
    },
    //删除广告通知消息
    async delAdvert(data) {
        return await axios_requer(apiList.api_delAdvert, data, 'post').then(res => res);
    },
    //删除广告banner
    async delAdvBanner(data) {
        return await axios_requer(apiList.api_delAdvBanner, data, 'post').then(res => res);
    },
    // ---------------渠道管理
    //新建或修改渠道
    async addOrUpdateChannel(data) {
        return await axios_requer(apiList.api_addOrUpdateChannel, data, 'post').then(res => res);
    },
    //获取渠道列表
    async getChannelList(data) {
        return await axios_requer(apiList.api_getChannelList, data).then(res => res);
    },
    //删除渠道
    async delChannel(data) {
        return await axios_requer(apiList.api_delChannel, data, 'post', 'json').then(res => res);
    },
    //新建或修改运输方式
    async addOrUpdateTransportMethod(data) {
        return await axios_requer(apiList.api_addOrUpdateTransportMethod, data, 'post').then(res => res);
    },
    //运输方式列表
    async getTransportMethodList(data) {
        return await axios_requer(apiList.api_getTransportMethodList, data).then(res => res);
    },
    //获取出货渠道含运输方式(下拉列表)
    async getChannelDownList(data) {
        return await axios_requer('/v1/admin/channel/getChannelDownList', data).then(res => res);
    },
    //新建或修改渠道物品类别
    async addOrUpdateChannelItemCategory(data) {
        return await axios_requer(apiList.api_addOrUpdateChannelItemCategory, data, 'post', 'json').then(res => res);
    },
    //渠道物品类别列表
    async getChannelItemCategoryList(data) {
        return await axios_requer(apiList.api_getChannelItemCategoryList, data).then(res => res);
    },
    //渠道物品类别列表(不分页)
    async getItemCategoryListNoPage(data) {
        return await axios_requer('/v1/admin/channel/getItemCategoryListNoPage', data).then(res => res);
    },
    //  获取渠道可出货物
    async getChannelItemCategory(data) {
        return await axios_requer('/v1/admin/channel/getChannelItemCategory', data).then(res => res);
    },
    //  修改渠道可出货物
    async updateChannelItemCategory(data) {
        return await axios_requer('/v1/admin/channel/updateChannelItemCategory', data, 'post', 'json').then(res => res);
    },
    //新建或修改渠道报价
    async addOrUpdateChannelOffer(data) {
        return await axios_requer(apiList.api_addOrUpdateChannelOffer, data, 'post').then(res => res);
    },
    //渠道报价列表
    async getChannelOfferList(data) {
        return await axios_requer(apiList.api_getChannelOfferList, data).then(res => res);
    },
    //删除渠道报价
    async delChannelOffer(data) {
        return await axios_requer(apiList.api_delChannelOffer, data, 'post', 'json').then(res => res);
    },
    //新建或修改渠道报价分区
    async addOrUpdateChannelOfferPartition(data) {
        return await axios_requer(apiList.api_addOrUpdateChannelOfferPartition, data, 'post', 'json').then(res => res);
    },
    //获取渠道报价分区列表
    async getChannelOfferPartitionList(data) {
        return await axios_requer(apiList.api_getChannelOfferPartitionList, data).then(res => res);
    },
    //删除渠道报价分区
    async delChannelOfferPartition(data) {
        return await axios_requer(apiList.api_delChannelOfferPartition, data, 'post').then(res => res);
    },
    //新建或修改才积除
    async addOrUpdateDivisor(data) {
        return await axios_requer(apiList.api_addOrUpdateDivisor, data, 'post').then(res => res);
    },
    //才积除列表
    async getDivisorList(data) {
        return await axios_requer(apiList.api_getDivisorList, data).then(res => res);
    },
    //新建或修改渠道报价重量段
    async addOrUpdateOffeWeightr(data) {
        return await axios_requer(apiList.api_addOrUpdateOffeWeightr, data, 'post').then(res => res);
    },
    //渠道报价重量段列表
    async getOfferWeightList(data) {
        return await axios_requer(apiList.api_getOfferWeightList, data).then(res => res);
    },
    //删除渠道报价重量段
    async delOfferWeight(data) {
        return await axios_requer(apiList.api_delOfferWeight, data, 'post').then(res => res);
    },
    //添加-修改渠道报价目录
    async addOrUpdateOfferList(data) {
        return await axios_requer(apiList.api_addOrUpdateOfferList, data, 'post').then(res => res);
    },
    //渠道报价目录列表
    async getOfferListList(data) {
        return await axios_requer(apiList.api_getOfferListList, data).then(res => res);
    },
    //删除渠道报价目录
    async delOfferList(data) {
        return await axios_requer(apiList.api_delOfferList, data, 'post').then(res => res);
    },
    //添加-修改渠道报价金额计算公式
    async addOrUpdateOfferFormula(data) {
        return await axios_requer(apiList.api_addOrUpdateOfferFormula, data, 'post').then(res => res);
    },
    //渠道报价金额计算公式列表
    async getOfferFormulaList(data) {
        return await axios_requer(apiList.api_getOfferFormulaList, data).then(res => res);
    },
    //删除渠道报价金额计算公式
    async delOfferFormula(data) {
        return await axios_requer(apiList.api_delOfferFormula, data, 'post', 'json').then(res => res);
    },
    //计费重量公式
    async getFormulaBillingWeithtAllList(data) {
        return await axios_requer(apiList.api_getFormulaBillingWeithtAllList, data).then(res => res);
    },
    //计费重量进位规则
    async getFormulaBillingWeithtCarryAllList(data) {
        return await axios_requer(apiList.api_getFormulaBillingWeithtCarryAllList, data).then(res => res);
    },
    //国家地区列表
    // async getAreaContryList(data) {
    //     return await axios_requer(apiList.api_getAreaContryList, data).then(res => res);
    // },
    //国家地区列表
    async getAreaContryList(data) {
        return await axios_requer('/v2/admin/area/getAreaContryList', data).then(res => res);
    },
    //国家地区列表(不分页)
    async getAreaContryList_q() {
        return await axios_requer('/v2/admin/area/getAreaContryAllList').then(res => res);
    },
    //国家地区列表(不分页)弃用
    // async getAreaContryList_q() { 
    //     return await axios_requer('/v1/admin/area/getAreaContryAllList').then(res => res);
    // },
    //添加-修改国家地区(弃用)
    // async addOrUpdateAreaContry(data) {
    //     return await axios_requer(apiList.api_addOrUpdateAreaContry, data, 'post').then(res => res);
    // },
    //添加国家地区
    async addAreaContry(data) {
        return await axios_requer("/v2/admin/area/addAreaContry", data, 'post').then(res => res);
    },
    //修改国家地区
    async updateAreaContry(data) {
        return await axios_requer("/v2/admin/area/updateAreaContry", data, 'post').then(res => res);
    },
    //启用禁用国家地区
    async startAreaContry(data) {
        return await axios_requer("/v2/admin/area/startAreaContry", data, 'post', 'json').then(res => res);
    },
    //删除国家地区
    async delAreaContry(data) {
        return await axios_requer("/v2/admin/area/delAreaContry", data, 'post', 'json').then(res => res);
    },
    //仓库打包列表
    async getStockPackList(data) {
        return await axios_requer('/v1/admin/pack/stockpack/getStockPackList', data, 'post', 'json').then(res => res);
    },
    //打包确认
    async packStorage(data) {
        return await axios_requer('/v1/dg/admin/pack/stockpack/packStorage', data, 'post', 'json').then(res => res);
        // return await axios_requer('/v1/admin/pack/stockpack/packStorage', data, 'post', 'json').then(res => res);
    },
    //取消打包确认
    async cancelPackStorage(data) {
        return await axios_requer('/v1/admin/pack/stockpack/cancelPackStorage', data, 'post').then(res => res);
    },
    //生成出库单号
    async createChannelNumber(data) {
        return await axios_requer('/v1/admin/pack/stockpack/createChannelNumber', data).then(res => res);
    },
    //  获取渠道物品名称列表
    async getItemNameList(data) {
        return await axios_requer('/v1/admin/channel/getItemNameList', data).then(res => res);
    },
    //  新增报价重量段
    async addOfferWeightAndMoney(data) {
        return await axios_requer('/v1/admin/channel/addOfferWeightAndMoney', data, 'post', 'json').then(res => res);
    },
    //  修改报价重量段
    async updateOfferWeightAndMoney(data) {
        return await axios_requer('/v1/admin/channel/updateOfferWeightAndMoney', data, 'post', 'json').then(res => res);
    },
    //  根据报价id获取报价详情
    async getChannelOfferDetail(data) {
        return await axios_requer('/v1/admin/channel/getChannelOfferDetail', data).then(res => res);
    },
    //  打印拣货单
    async pickingPrint(data) {
        return await axios_requer('/v1/admin/pack/package/pickingPrint', data, 'post', 'json').then(res => res);
    },


    // 财务管理---------------------------
    //应收计费列表
    async getFinanceInChargingList(data) {
        return await axios_requer('/v1/admin/finance/in/getFinanceInChargingList', data, 'post', 'json').then(res => res);
    },
    //应收计费列表==审核和反审核
    async financeExamine(data) {
        return await axios_requer('/v1/dg/admin/finance/in/financeExamine', data, 'post', 'json').then(res => res);
        // return await axios_requer('/v1/admin/finance/in/financeExamine', data, 'post', 'json').then(res => res);
    },
    //获取应收费项-其他费项
    async financeInOtherServiceList(data) {
        return await axios_requer('/v1/admin/finance/in/financeInOtherServiceList', data).then(res => res);
    },
    //收款核销列表
    async collectionWriteOffList(data) {
        return await axios_requer('/v1/admin/finance/in/collectionWriteOffList', data, 'post', 'json').then(res => res);
    },
    //核销
    async writeOff(data) {
        return await axios_requer('/v1/admin/finance/in/writeOff', data, 'post', 'json').then(res => res);
    },
    //返核销
    async cancelWriteOff(data) {
        return await axios_requer('/v1/admin/finance/in/cancelWriteOff', data, 'post', 'json').then(res => res);
    },
    //获取应收计费详情-仓库打包单据类型详情
    async financeStorepackDetail(data) {
        return await axios_requer('/v1/admin/finance/in/financeStorepackDetail', data).then(res => res);
    },
    //获取应收计费详情-验货单据类型
    async getFinanceInChargingInspectionDetail(data) {
        return await axios_requer('/v1/admin/finance/in/getFinanceInChargingInspectionDetail', data).then(res => res);
    },
    //获取应收计费详情-拒收单据类型
    async getFinanceInChargingRejectionDetail(data) {
        return await axios_requer('/v1/admin/finance/in/getFinanceInChargingRejectionDetail', data).then(res => res);
    },
    //修改费项-修改其他费用
    async updateFinanceInOtherService(data) {
        return await axios_requer('/v1/admin/finance/in/updateFinanceInOtherService', data, 'post', 'json').then(res => res);
    },
    //修改应收费项
    async updateFinanceIn(data) {
        return await axios_requer('/v1/admin/finance/in/updateFinanceIn', data, 'post').then(res => res);
    },
    // //会员充值列表(弃用)
    // async memberRechargeList(data) {
    //     return await axios_requer('/v1/admin/finance/in/memberRechargeList', data).then(res => res);
    // },
    //会员充值列表
    async memberRechargeList(data) {
        return await axios_requer('/v2/admin/member/recharge/getMemberRechargeList', data).then(res => res);
    },
    //充值处理
    async handleMemberRecharge(data) {
        return await axios_requer('/v1/admin/finance/in/handleMemberRecharge', data, 'post').then(res => res);
    },
    //充值处理详情
    async handleMemberRechargeDetail(data) {
        return await axios_requer('/v1/admin/finance/in/handleMemberRechargeDetail', data).then(res => res);
    },
    //新建充值申请
    async rechargeTransfer(data) {
        return await axios_requer('/v1/admin/finance/in/rechargeTransfer', data, 'post', 'json').then(res => res);
    },
    //修改充值申请
    async updateMemberRecharge(data) {
        return await axios_requer('/v1/admin/finance/in/updateMemberRecharge', data, 'post', 'json').then(res => res);
    },

    //资金账户下拉列表 
    async capitalAccountList(data) {
        return await axios_requer('/v1/admin/finance/out/capitalAccountList', data, 'post').then(res => res);
    },
    //会员银行下拉列表
    async memberBankList(data) {
        return await axios_requer('/v2/admin/member/recharge/memberBankList', data).then(res => res);
    },
    //取消资金账户申请
    // async deleteAssetaccount(data) {
    //     return await axios_requer('/v1/admin/finance/assetaccount/deleteAssetaccount', data, 'post').then(res => res);
    // },
    //币种下拉列表
    async getCurrencyDownList(data) {
        return await axios_requer('/v1/admin/finance/currency/getCurrencyDownList', data).then(res => res);
    },
    //会员充值详情
    async memberRechargeDetail(data) {
        return await axios_requer('/v1/admin/finance/in/memberRechargeDetail', data).then(res => res);
    },

    //控制面板数据
    async getReportCount(data) {
        return await axios_requer('/v1/admin/report/count/getReportCount', data).then(res => res);
    },

    //渠道代理下拉列表
    async channelAgentList(data) {
        return await axios_requer('/v1/admin/channelAgent/channelAgentList', data).then(res => res)
    },
    //应付管理
    async financeOutList(data) {
        return await axios_requer(apiList.api_financeOutList, data).then(res => res)
    },
    //应付核销
    async financeOutWriteoffList(data) {
        return await axios_requer(apiList.api_financeOutWriteoffList, data).then(res => res)
    },


    //资金管理模块(wtl)
    //资金账号列表
    async assetaccountList(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/assetaccountList', data).then(res => res)
    },


    //  ---------- 会员模块 ---------


    //会员管理---------------------------------------------------
    async getMemberList(data) {
        return await axios_requer('/v1/admin/member/getMemberList', data).then(res => res);
    },
    //新增会员
    async addMember(data) {
        return await axios_requer('/v1/admin/member/addMember', data, 'post').then(res => res);
    },
    //暂停\启用
    async memberEnable(data) {
        return await axios_requer('/v1/admin/member/memberEnable', data, 'post', 'json').then(res => res);
    },
    //会员号黑名单
    async memberBlacklist(data) {
        return await axios_requer('/v1/admin/member/memberBlacklist', data).then(res => res);
    },
    //会员流水账
    async memberAccountRecordList(data) {
        return await axios_requer('/v1/admin/member/memberAccountRecordList', data).then(res => res);
    },
    //修改会员
    async updateMember(data) {
        return await axios_requer('/v1/admin/member/updateMember', data, 'post').then(res => res);
    },
    //获取所有语言代码
    async languageCodeAll(data) {
        return await axios_requer('/common/v1/dict/languageCodeAll', data).then(res => res)
    },
    //会员余额表
    async financeInMemberBalanceList(data) {
        return await axios_requer('/v1/admin/finance/in/financeInMemberBalanceList', data).then(res => res)
    },
    //系统管理
    //   ------- 包裹拼团模块  ----------
    //  包裹拼团列表
    async packageTeamList(data) {
        return await axios_requer('/admin/v1/team/packageTeamList', data, 'post', 'json').then(res => res);
    },
    //  拼团包裹明细列表
    async packageTeamDetails(data) {
        return await axios_requer('/admin/v1/team/packageTeamPackageList', data).then(res => res);
    },
    //  添加包裹拼团
    async addPackageTeam(data) {
        return await axios_requer('/admin/v1/team/addPackageTeam', data, 'post').then(res => res);
    },
    //  未入团列表
    async notJoinTeamList(data) {
        return await axios_requer('/admin/v1/team/notJoinTeamList', data).then(res => res);
    },
    //  已入团列表
    async joinTeamList(data) {
        return await axios_requer('/admin/v1/team/joinTeamList', data).then(res => res);
    },
    //  加入拼团
    async addPackageTeamPackage(data) {
        return await axios_requer('/admin/v1/team/addPackageTeamPackage', data, 'post').then(res => res);
    },
    //  启用-暂停拼团
    async onoffPackage(data) {
        return await axios_requer('/admin/v1/team/packageTeamEnable', data, 'post').then(res => res);
    },
    //  修改货架
    async updateStorageCargoareaFrame(data) {
        return await axios_requer('/admin/v1/storage/updateStorageCargoareaFrame', data, 'post').then(res => res);
    },
    //  渠道代理选择列表
    async channelAgentChooseList(data) {
        return await axios_requer('/v1/admin/channelAgent/channelAgentChooseList', data).then(res => res);
    },
    //删除货区
    async deleteStorageCargoarea(data) {
        return await axios_requer('/admin/v1/storage/deleteStorageCargoarea', data, 'post').then(res => res)
    },
    //删除货架
    async deleteStorageCargoareaFrame(data) {
        return await axios_requer('/admin/v1/storage/deleteStorageCargoareaFrame', data, 'post').then(res => res)
    },

    //  ---------------  快递公司模块  ---------
    //  快递公司下拉列表
    async expressChooseList(data) {
        return await axios_requer('/v1/admin/express/expressChooseList', data).then(res => res);
    },
    //其他应收和客户加收列表
    async otherFinanceInList(data) {
        return await axios_requer('/v1/admin/finance/in/otherFinanceInList', data, 'post', 'json').then(res => res);
    },
    //其他应收核销
    async otherFinanceInWriteOff(data) {
        return await axios_requer('/v1/admin/finance/other/otherFinanceInWriteOff', data, 'post', 'json').then(res => res);
    },
    //其他应收反核销
    async otherFinanceInCancelWriteOff(data) {
        return await axios_requer('/v1/admin/finance/other/otherFinanceInCancelWriteOff', data, 'post', 'json').then(res => res);
    },
    //其他应收审核反审核
    async otherFinanceInExamine(data) {
        return await axios_requer('/v1/admin/finance/other/otherFinanceInExamine', data, 'post', 'json').then(res => res);
    },
    //客户加收申请
    async addCustomerAdditional(data) {
        return await axios_requer('/v1/admin/finance/additional/addCustomerAdditional', data, 'post').then(res => res);
    },
    //客户加收详情
    async customerAdditionalDetail(data) {
        return await axios_requer('/v1/admin/finance/additional/customerAdditionalDetail', data).then(res => res);
    },
    //修改客户加收
    async updateCustomerAdditional(data) {
        return await axios_requer('/v1/admin/finance/additional/updateCustomerAdditional', data, 'post').then(res => res);
    },
    //客户加收审核 返审核
    async customerExamine(data) {
        return await axios_requer('/v1/admin/finance/additional/customerExamine', data, 'post', 'json').then(res => res);
    },
    //客户加收核销
    async customerWriteOff(data) {
        return await axios_requer('/v1/admin/finance/additional/customerWriteOff', data, 'post', 'json').then(res => res);
    },
    //客户加收返核销
    async cancelCustomerWriteOff(data) {
        return await axios_requer('/v1/admin/finance/additional/cancelCustomerWriteOff', data, 'post', 'json').then(res => res);
    },
    //其他收入详情
    async otherFinanceInDetail(data) {
        return await axios_requer('/v1/admin/finance/in/otherFinanceInDetail', data).then(res => res);
    },
    //新增资金账号
    async addAssetaccount(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/addAssetaccount', data, 'post', 'json').then(res => res);
    },
    //修改资金账号
    async updateAssetaccount(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/updateAssetaccount', data, 'post', 'json').then(res => res);
    },
    //资金账号详情
    async assetaccountDatail(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/assetaccountDatail', data).then(res => res);
    },
    //审核资金账号
    async handleIsShow(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/handleIsShow', data, 'post', 'json').then(res => res);
    },
    //员工列表
    async userEmployeeList(data) {
        return await axios_requer('/v1/admin/employee/userEmployeeList', data).then(res => res);
    },
    //部门列表
    async departmentList(data) {
        return await axios_requer('/v1/admin/employee/departmentList', data).then(res => res);
    },
    //部门下拉列表
    async departmentChooseList(data) {
        return await axios_requer('/v1/admin/employee/departmentChooseList', data).then(res => res);
    },
    //添加部门
    async addDepartment(data) {
        return await axios_requer('/v1/admin/employee/addDepartment', data, 'post').then(res => res);
    },
    //获取部门详情
    async departmentDetail(data) {
        return await axios_requer('/v1/admin/employee/departmentDetail', data).then(res => res);
    },
    //修改部门
    async updateDepartment(data) {
        return await axios_requer('/v1/admin/employee/updateDepartment', data, 'post').then(res => res);
    },
    //部门类型下拉列表
    async getdepartmentTypeList(data) {
        return await axios_requer('/v1/admin/employee/getdepartmentTypeList', data).then(res => res);
    },
    //新建员工
    async addUserEmployee(data) {
        return await axios_requer('/v1/admin/employee/addUserEmployee', data, 'post').then(res => res);
    },
    //员工下拉列表
    async employeeChooseList(data) {
        return await axios_requer('/v1/admin/employee/employeeChooseList', data).then(res => res);
    },
    //获取员工详情
    async userEmployeeDetail(data) {
        return await axios_requer('/v1/admin/employee/userEmployeeDetail', data).then(res => res);
    },
    //修改员工
    async updateUserEmployee(data) {
        return await axios_requer('/v1/admin/employee/updateUserEmployee', data, 'post').then(res => res);
    },
    //角色管理列表
    async roleList(data) {
        return await axios_requer('/v1/admin/role/roleList', data).then(res => res);
    },
    //添加角色
    async addRole(data) {
        return await axios_requer('/v1/admin/role/addRole', data, 'post').then(res => res);
    },
    //删除角色
    async deleteRole(data) {
        return await axios_requer('/v1/admin/role/deleteRole', data, 'post', 'json').then(res => res);
    },
    //修改角色
    async updateRole(data) {
        return await axios_requer('/v1/admin/role/updateRole', data, 'post').then(res => res);
    },
    //角色详情
    async roleDetail(data) {
        return await axios_requer('/v1/admin/role/roleDetail', data).then(res => res);
    },
    //银行列表
    async bankList(data) {
        return await axios_requer('/v1/admin/bank/bankList', data).then(res => res);
    },
    //添加银行
    async addBank(data) {
        return await axios_requer('/v1/admin/bank/addBank', data, 'post').then(res => res);
    },
    //删除银行
    async deleteBank(data) {
        return await axios_requer('/v1/admin/bank/deleteBank', data, 'post').then(res => res);
    },
    //银行详情
    async bankDetail(data) {
        return await axios_requer('/v1/admin/bank/bankDetail', data).then(res => res);
    },
    //修改银行
    async updateBank(data) {
        return await axios_requer('/v1/admin/bank/updateBank', data, 'post').then(res => res);
    },
    //货币类型列表
    async currencyList(data) {
        return await axios_requer('/v1/admin/currency/currencyList', data).then(res => res);
    },
    //新增货币类型
    async addCurrencyList(data) {
        return await axios_requer('/v1/admin/currency/addCurrencyList', data, 'post').then(res => res);
    },
    //删除货币类型
    async deleteCurrency(data) {
        return await axios_requer('/v1/admin/currency/deleteCurrency', data, 'post').then(res => res);
    },
    //货币类型详情
    async currencyDetail(data) {
        return await axios_requer('/v1/admin/currency/currencyDetail', data).then(res => res);
    },
    //修改货币类型
    async updateCurrencyList(data) {
        return await axios_requer('/v1/admin/currency/updateCurrencyList', data, 'post').then(res => res);
    },
    //快递公司列表
    async expressList(data) {
        return await axios_requer('/v1/admin/express/expressList', data).then(res => res);
    },
    //新增快递公司
    async addExpress(data) {
        return await axios_requer('/v1/admin/express/addExpress', data, 'post').then(res => res);
    },
    //快递公司详情
    async expressDetail(data) {
        return await axios_requer('/v1/admin/express/expressDetail', data).then(res => res);
    },
    //快递公司修改
    async updateExpress(data) {
        return await axios_requer('/v1/admin/express/updateExpress', data, 'post').then(res => res);
    },
    //获取登录用户的菜单
    async getLoginUserMenu(data) {
        return await axios_requer('/v1/admin/role/getLoginUserMenu', data).then(res => res);
    },
    //保存角色菜单权限
    async saveRoleMenu(data) {
        return await axios_requer('/v1/admin/role/saveRoleMenu', data, 'post', 'json').then(res => res);
    },
    //保存单个用户的菜单权限
    async savaUserPermission(data) {
        return await axios_requer('/v2/admin/savaUserPermission', data, 'post', 'json').then(res => res);
    },
    //保存角色权限(弃用)
    // async saveRolePermission(data) {
    //     return await axios_requer('/v1/admin/role/saveRolePermission', data, 'post', 'json').then(res => res);
    // },
    //保存角色权限 
    async saveRolePermission(data) {
        return await axios_requer('/v2/admin/role/saveRolePermission', data, 'post', 'json').then(res => res);
    },
    //角色权限列表
    async getRolePermissionList(data) {
        return await axios_requer('/v1/admin/role/getRolePermissionList', data, 'post').then(res => res);
    },
    // //用户角色权限(当前登录用户全部权限)
    // async getUserRolePermissionList(data) {
    //     return await axios_requer('/v1/admin/role/getUserRolePermissionList', data).then(res => res);
    // },
    //角色菜单权限列表(当前角色全部菜单和权限)
    async getRoleMenuPermissionList(data) {
        return await axios_requer('/v1/admin/role/getRoleMenuPermissionList', data).then(res => res);
    },
    //用户角色菜单(当前登录用户全部菜单和权限)(弃用)
    // async getLoginUserRoleMenuPerList(data) {
    //     return await axios_requer('/v1/admin/role/getLoginUserRoleMenuPerList', data).then(res => res);
    // },
    //用户角色菜单(当前登录用户全部菜单和权限)
    async getLoginUserRoleMenuPerList(data) {
        return await axios_requer('/v2/admin/getLoginUserRoleMenuPerList', data).then(res => res);
    },


    //启用/禁用账户
    async enableOrDisable(data) {
        return await axios_requer('/admin/v1/adminUser/enableOrDisable', data, 'post', 'json').then(res => res);
    },
    //获取金额计算公式变量
    async getDictSqlVariableList(data) {
        return await axios_requer('/v1/admin/channel/getDictSqlVariableList', data).then(res => res);
    },
    //修改收支
    async updateOtherFinance(data) {
        return await axios_requer('/v1/admin/finance/other/updateOtherFinance', data, 'post').then(res => res);
    },
    //管理员列表
    async adminUserList(data) {
        return await axios_requer('/admin/v1/adminUser/adminUserList', data).then(res => res);
    },
    //资金账号取消申请
    async deleteAssetaccount(data) {
        return await axios_requer('/v1/admin/finance/assetaccount/deleteAssetaccount', data, 'post', 'json').then(res => res);
    },
    //生成应付单号
    async createFinanceOutNumber(data) {
        return await axios_requer('/v1/admin/finance/outImport/createFinanceOutNumber', data).then(res => res);
    },
    //删除代理加收
    async deleteAgentAdditional(data) {
        return await axios_requer('/v1/admin/finance/additional/deleteAgentAdditional', data, 'post', 'json').then(res => res);
    },
    //删除其他支出
    async deleteOtherFinanceOut(data) {
        return await axios_requer('/v1/admin/finance/other/deleteOtherFinanceOut', data, 'post', 'json').then(res => res);
    },
    //删除渠道代理
    async deleteChannelAgent(data) {
        return await axios_requer('/v1/admin/channelAgent/deleteChannelAgent', data, 'post', 'json').then(res => res);
    },
    //渠道管理启用禁用
    async channelIsEnable(data) {
        return await axios_requer('/v1/admin/channel/channelIsEnable', data, 'post', 'json').then(res => res);
    },
    //渠道报价启用禁用
    async channelOfferIsEnable(data) {
        return await axios_requer('/v1/admin/channel/channelOfferIsEnable', data, 'post', 'json').then(res => res);
    },
    //包裹上加记录
    async packageUpframeRecord(data) {
        return await axios_requer('/v1/admin/pack/upframe/packageUpframeRecord', data, 'post', 'json').then(res => res);
    },
    //员工下拉列表
    // async userEmployeeChooseList(data) {
    //     return await axios_requer('/v1/admin/employee/userEmployeeChooseList', data).then(res => res);
    // },
    async userEmployeeChooseList(data) {
        return await axios_requer('/v1/admin/employee/employeeChooseList', data).then(res => res);
    },
    //资金流水账列表 
    async fundFlows(data) {
        return await axios_requer('/v2/admin/finance/fund/fundFlows', data, 'post', 'json').then(res => res);
    },
    //对账 取消对账 
    async cancelReconciliation(data) {
        return await axios_requer('/v2/admin/finance/fund/cancelReconciliation', data, 'post').then(res => res);
    },
    //修改包裹 
    async updatePackage(data) {
        return await axios_requer('/v1/admin/pack/updatePackage', data, 'post', 'json').then(res => res);
    },
    //修改拼团 
    async updateTeamPackPackage(data) {
        return await axios_requer('/v1/menber/team/updateTeamPackPackage', data, 'post', 'json').then(res => res);
    },
    //拼团详情
    async getFinishTeamDetail(data) {
        return await axios_requer('/v1/menber/team/getFinishTeamDetail', data).then(res => res)
    },
    //导入费用 
    async importCost(data) {
        return await axios_requer('/v1/admin/finance/out/importCost', data, 'post', 'json').then(res => res);
    },
    //获取打包服务项列表 
    async getItemServiceDaBaoList(data) {
        return await axios_requer('/v1/admin/pack/stockpack/getItemServiceDaBaoList', data).then(res => res);
    },
    //  根据服务类型获取服务项
    async getItemServiceList_alls(data) {
        return await axios_requer('/v2/admin/item/service/getItemServiceList', data).then(res => res);
    },
    //获取仓库打包单详情 
    async getStockPackDetail(data) {
        return await axios_requer('/v1/admin/pack/stockpack/getStockPackDetail', data).then(res => res);
    },
    //取消打包 
    async cancelPack(data) {
        return await axios_requer('/v1/admin/pack/stockpack/cancelPack', data, 'post').then(res => res);
    },
    //创建51订单 
    async create51TrackOrder(data) {
        return await axios_requer('/common/v1/track/api/create51TrackOrder', data, 'post', 'json').then(res => res);
    },
    //获取51物流轨迹
    async get51Track(data) {
        return await axios_requer('/common/v1/track/api/get51Track', data).then(res => res);
    },
    //获取验货申请详情 
    async inspectionId(data) {
        return await axios_requer('/v1/package/inspectionId', data).then(res => res);
    },
    //拼团详情 
    async packageTeamDetail(data) {
        return await axios_requer('/admin/v1/team/packageTeamDetail', data).then(res => res);
    },
    //修改拼团 
    async updatePackageTeam(data) {
        return await axios_requer('/admin/v1/team/updatePackageTeam', data, 'post').then(res => res);
    },
    //团类别选择列表 
    async packageTeamGroupChooseList(data) {
        return await axios_requer('/admin/v1/team/packageTeamGroupChooseList', data).then(res => res);
    },
    //仓库打包明细列表 
    async storagePackDetailList(data) {
        return await axios_requer('/v1/admin/pack/stockpack/storagePackDetailList', data, 'post', 'json').then(res => res);
    },
    //取消入库 
    async cancelRuKuPackage(data) {
        return await axios_requer('/v1/admin/pack/instock/cancelRuKuPackage', data, 'post', 'json').then(res => res);
    },
    //取消上架 
    async cancelUpFrame(data) {
        return await axios_requer('/v1/admin/pack/upframe/cancelUpFrame', data, 'post', 'json').then(res => res);
    },
    //打包修改费项详情 
    async billPackItemServiceDetail(data) {
        return await axios_requer('/v1/admin/billpack/modify/billPackItemServiceDetail', data).then(res => res);
    },
    //  申请打包单关联的包裹列表
    async checkedPackList(data) {
        return await axios_requer('/v1/admin/billpack/modify/checkedPackList', data).then(res => res);
    },
    //  申请打包单参数
    async billPackDesc(data) {
        return await axios_requer('/v1/admin/billpack/modify/billPackDesc', data).then(res => res);
    },
    //生成团号
    async createTeamCode(data) {
        return await axios_requer('/admin/v1/team/createTeamCode', data).then(res => res);
    },
    //批量添加包裹 
    async batchAddPackage(data) {
        return await axios_requer('/v1/admin/pack/batchAddPackage', data, 'post', 'json').then(res => res);
    },
    //添加账户 
    async addUser(data) {
        return await axios_requer('/admin/v1/adminUser/addUser', data, 'post', 'json').then(res => res);
    },
    //账户详情 
    async userDetail(data) {
        return await axios_requer('/admin/v1/adminUser/userDetail', data).then(res => res);
    },
    //修改账户 
    async updateUser(data) {
        return await axios_requer('/admin/v1/adminUser/updateUser', data, 'post', 'json').then(res => res);
    },
    //关联角色权限
    async adminUserRelatedRole(data) {
        return await axios_requer('/v2/admin/adminUserRelatedRole', data, 'post', 'json').then(res => res);
    },
    // async adminUserRelatedRole(data) {
    //     return await axios_requer('/admin/v1/adminUser/adminUserRelatedRole', data, 'post', 'json').then(res => res);
    // },
    //催款 
    async pressForMoney(data) {
        return await axios_requer('/v1/admin/finance/in/pressForMoney', data, 'post', 'json').then(res => res);
    },
    //分配权限详情 
    async getRelatedRole(data) {
        return await axios_requer('/admin/v1/adminUser/getRelatedRole', data).then(res => res);
    },
    //会员详情 memberDetail
    async memberDetail(data) {
        return await axios_requer('/v1/admin/member/memberDetail', data).then(res => res)
    },
    //收件地址列表 
    async userAddressList(data) {
        return await axios_requer('/v1/admin/member/userAddressList', data).then(res => res)
    },
    //收件地址详情
    async userAddressDetail(data) {
        return await axios_requer('/v1/admin/member/userAddressDetail', data).then(res => res)
    },
    //批量导入分区
    async batchAddOfferPartition(data) {
        return await axios_requer('/v1/admin/channel/batchAddOfferPartition', data, 'post', 'json').then(res => res)
    },
    //我的信息
    async personalInfo(data) {
        return await axios_requer('/v1/admin/personal/personalInfo', data).then(res => res)
    },
    //修改密码 
    async updatePassword(data) {
        return await axios_requer('/v1/admin/personal/updatePassword', data, 'post').then(res => res)
    },
    //获取会员等级下拉列表 
    async getMemberLeveDownList(data) {
        return await axios_requer('/v1/admin/member/getMemberLeveDownList', data).then(res => res)
    },
    //部门层级
    async departmentHierarchy(data) {
        return await axios_requer('/v1/admin/employee/departmentHierarchy', data, 'post').then(res => res)
    },
    //复制渠道
    async copyChannelData(data) {
        return await axios_requer('/v2/admin/channel/copyChannelData', data, 'post').then(res => res)
    },
    //删除部门
    async deleteDepartment(data) {
        return await axios_requer('/v1/admin/employee/deleteDepartment', data, 'post').then(res => res)
    },
    //启用禁用部门
    async departmentDisable(data) {
        return await axios_requer('/v1/admin/employee/departmentDisable', data, 'post', 'json').then(res => res)
    },
    //取消下架
    async cancelDownframe(data) {
        return await axios_requer('/v1/admin/pack/downframe/cancelDownframe', data, 'post', 'json').then(res => res)
    },
    //取消下架详情
    async cancelPickDownDetail(data) {
        return await axios_requer('/v1/admin/pack/downframe/cancelPickDownDetail', data).then(res => res)
    },
    //会员号修改审核列表
    async getCheckMemberIdList(data) {
        return await axios_requer('/v1/admin/member/check/getCheckMemberIdList', data).then(res => res)
    },
    //审核会员号修改
    async checkMemberId(data) {
        return await axios_requer('/v1/admin/member/check/checkMemberId', data, 'post').then(res => res)
    },
    //会员列表下拉框
    async getMemberDownList(data) {
        return await axios_requer('/v1/admin/member/getMemberDownList', data).then(res => res)
    },
    //获取logo图片
    async logoInfo(data) {
        return await axios_requer('/v1/admin/Logo/logoInfo', data).then(res => res)
    },
    //上传logo图片
    async addLogo(data) {
        return await axios_requer('/v1/admin/Logo/addLogo', data, 'post').then(res => res)
    },
    //批量到库签收
    async batchSignPackage(data) {
        return await axios_requer('/v1/admin/pack/signfor/batchSignPackage', data, 'post', 'json').then(res => res)
    },
    //批量认领
    async batchClaimPackage(data) {
        return await axios_requer('/v1/admin/pack/claim/batchClaimPackage', data, 'post', 'json').then(res => res)
    },
    //登录用户的仓库
    async loginUserStorageDownList(data) {
        return await axios_requer('/admin/v1/storage/loginUserStorageDownList', data).then(res => res)
    },
    //用户定制参数配置
    async addUserCustomizedConf(data) {
        return await axios_requer('/v1/admin/customized/addUserCustomizedConf', data, 'post').then(res => res)
    },
    //用户定制参数信息
    async userCustomizedConfInfo(data) {
        return await axios_requer('/v1/admin/customized/userCustomizedConfInfo', data).then(res => res)
    },
    //称重机设备列表
    async getDeviceList(data) {
        return await axios_requer('/v1/device/getDeviceList', data).then(res => res)
    },
    //获取称重机设备重量数据
    async getWeight(data) {
        return await axios_requer('/v1/device/getWeight', data, 'GET', '', false).then(res => res)
    },
    //智能入库根据快递单号查询详情
    async intellectRuKuDetail(data) {
        return await axios_requer('/v1/admin/pack/instock/intellectRuKuDetail', data, 'GET', '', true, false).then(res => res)
    },
    //货区详情
    async storageCargoareaDetail(data) {
        return await axios_requer('/admin/v1/storage/storageCargoareaDetail', data).then(res => res)
    },
    //注册会员
    async registerMember(data) {
        return await axios_requer('/v1/admin/member/registerMember', data, 'post', 'json').then(res => res)
    },
    //新建会员收货地址
    async addUserAddress(data) {
        return await axios_requer('/v1/admin/member/addUserAddress', data, 'post').then(res => res)
    },
    //新建会员收货地址
    async addUserAddress(data) {
        return await axios_requer('/v1/admin/member/addUserAddress', data, 'post').then(res => res)
    },
    //修改会员收货地址
    async updateUserAddress(data) {
        return await axios_requer('/v1/admin/member/updateUserAddress', data, 'post').then(res => res)
    },
    //删除会员收货地址
    async deleteUserAddress(data) {
        return await axios_requer('/v1/admin/member/deleteUserAddress', data, 'post', 'json').then(res => res)
    },
    //会员收货地址详情
    async userAddressDetail(data) {
        return await axios_requer('/v1/admin/member/userAddressDetail', data).then(res => res)
    },
    //会员收货地址详情
    async packDetail(data) {
        return await axios_requer('/v1/admin/pack/packDetail', data).then(res => res)
    },
    //会员余额核销
    async memberAccountWriteOff(data) {
        return await axios_requer('/v1/admin/finance/in/memberAccountWriteOff', data, 'post', 'json').then(res => res)
    },
    //应收会员余额详情
    async financeInMemberBalanceDetail(data) {
        return await axios_requer('/v1/admin/finance/in/financeInMemberBalanceDetail', data).then(res => res)
    },
    //修改打包单保存接口
    async savePackingInfo(data) {
        return await axios_requer('/v1/admin/pack/stockpack/updateBillPack', data, 'post', 'json').then(res => res)
    },
    //未读消息数量接口
    async unReadMessageApi(data) {
        return await axios_requer('/v1/admin/push/adminmsg/getUnreadCount').then(res => res)
    },
    //消息列表接口
    async adminmsgListApi(data) {
        return await axios_requer('/v1/admin/push/adminmsg/adminmsgList', data).then(res => res)
    },
    //标记已读消息接口
    async isReadApi(data) {
        return await axios_requer('/v1/admin/push/adminmsg/isRead', data, 'post', 'json').then(res => res)
    },
    //查看消息详情接口
    async getMsgDetail(data) {
        return await axios_requer('/v1/admin/push/adminmsg/getMsgDetail', data).then(res => res)
    },
    //会员余额应收催款 
    async memberPressForMoney(data) {
        return await axios_requer('/v1/admin/finance/in/memberPressForMoney', data, 'post').then(res => res);
    },
    //服务协议配置列表 
    async configAgreementList(data) {
        return await axios_requer('/v1/admin/config/configAgreementList', data).then(res => res);
    },
    //修改会员密码 
    async updateMemberPwd(data) {
        return await axios_requer('/v1/admin/member/updateMemberPwd', data, 'post').then(res => res);
    },
    //客服列表 
    async getKfContactList(data) {
        return await axios_requer('/v1/admin/kf/contact/getKfContactList', data).then(res => res);
    },
    //新增客服 
    async addKfContact(data) {
        return await axios_requer('/v1/admin/kf/contact/addKfContact', data, 'post', 'json').then(res => res);
    },
    //删除客服 
    async delKfContact(data) {
        return await axios_requer('/v1/admin/kf/contact/delKfContact', data, 'post', 'json').then(res => res);
    },
    //修改客服 
    async updateKfContact(data) {
        return await axios_requer('/v1/admin/kf/contact/updateKfContact', data, 'post', 'json').then(res => res);
    },
    //根据用户id获取用户的菜单权限列表 
    async getUserRoleMenuPerList(data) {
        return await axios_requer('/v2/admin/getUserRoleMenuPerList', data).then(res => res);
    },
    //根据用户id获取用户的菜单权限列表 
    async memberCustomerWriteOff(data) {
        return await axios_requer('/v1/admin/finance/additional/memberCustomerWriteOff', data, 'post', 'json').then(res => res);
    },

    //添加服务协议配置 
    async addConfigAgreement(data) {
        return await axios_requer('/v1/admin/config/addConfigAgreement', data, 'post').then(res => res);
    },
    //服务协议详情 
    async configAgreementDetail(data) {
        return await axios_requer('/v1/admin/config/configAgreementDetail', data).then(res => res);
    },
    //修改服务协议配置
    async updateConfigAgreement(data) {
        return await axios_requer('/v1/admin/config/updateConfigAgreement', data, 'post', 'json').then(res => res);
    },

    //文章列表(代购+集运)
    async articleList(data) {
        return await axios_requer('/admin/v1/article/articleList', data).then(res => res);
    },
    //文章类型选择列表
    async articleTypeChooseList(data) {
        return await axios_requer('/admin/v1/article/articleTypeChooseList', data).then(res => res);
    },
    //新建文章
    async addArticle(data) {
        return await axios_requer('/admin/v1/article/addArticle', data, 'post', 'json').then(res => res);
    },
    //删除文章
    async deleteArticle(data) {
        return await axios_requer('/admin/v1/article/deleteArticle', data, 'post', 'json').then(res => res);
    },
    //删除文章类型
    async deleteArticleType(data) {
        return await axios_requer('/admin/v1/article/deleteArticleType', data, 'post', 'json').then(res => res);
    },
    //文章启用
    async isEnableArticle(data) {
        return await axios_requer('/admin/v1/article/isEnableArticle', data, 'post', 'json').then(res => res);
    },
    //文章启用
    async unEnableArticle(data) {
        return await axios_requer('/admin/v1/article/unEnableArticle', data, 'post', 'json').then(res => res);
    },
    //文章修改
    async updateArticle(data) {
        return await axios_requer('/admin/v1/article/updateArticle', data, 'post', 'json').then(res => res);
    },
    //文章详情
    async articleDetail(data) {
        return await axios_requer('/admin/v1/article/articleDetail', data).then(res => res);
    },
    //获取logo地址
    async getLogo(data) {
        return await axios_requer('/common/v1/tenant/getLogo', data).then(res => res);
    },
    //获取租户登陆页信息
    async getAdminLoginPic(data) {
        return await axios_requer('/common/v1/tenant/getAdminLoginPic', data).then(res => res);
    },















    // 八米购
    //出库确认接口
    async bmg_outStock(data) {
        return await axios_requer('/admin/v1/pack/instock/outStock', data, 'post', 'json').then(res => res);
    },
    //出库取消接口
    async bmg_cancelOutStock(data) {
        return await axios_requer('/admin/v1/pack/instock/cancelOutStock', data, 'post', 'json').then(res => res);
    },
    //包裹入库
    async bmg_ruKuPackage(data) {
        return await axios_requer('/admin/v1/dg/instock/ruKuPackage', data, 'post', 'json').then(res => res);
    },
    //包裹到库签收(单个)
    async bmg_signPackage(data) {
        return await axios_requer('/admin/v1/dg/sign/signPackage', data, 'post').then(res => res);
    },
    //包裹到库签收(批量)
    async bmg_batchSignPackage(data) {
        return await axios_requer('/admin/v1/dg/sign/batchSignPackage', data, 'post', 'json').then(res => res);
    },
    //获取代购订单列表
    async bmg_getDgOrderList(data) {
        return await axios_requer('/admin/v1/dg/order/getDgOrderList', data).then(res => res);
    },
    //获取采购订单列表
    async bmg_getPurchaseOrderList(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/getPurchaseOrderList', data).then(res => res);
    },
    //新增采购单(测试接口)
    async bmg_createPurchaseOrder(data) {
        return await axios_requer('/dg/test/createPurchaseOrder', data, 'post').then(res => res);
    },
    //新增包裹(采购单)
    async bmg_addPurchaseOrderPackage(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/addPurchaseOrderPackage', data, 'post', 'json').then(res => res);
    },
    // //新增包裹(测试接口)
    // async bmg_createPackage(data) {
    //     return await axios_requer('/dg/test/createPackage', data, 'post').then(res => res);
    // },
    //取消代购单
    async bmg_cancelDgOrder(data) {
        return await axios_requer('/admin/v1/dg/order/cancelDgOrder', data, 'post', 'json').then(res => res);
    },
    //代购单退款
    async bmg_refundDgOrder(data) {
        return await axios_requer('/admin/v1/dg/order/refundDgOrder', data, 'post', 'json').then(res => res);
    },
    //新增代购单
    async bmg_addDgOrder(data) {
        return await axios_requer('/admin/v1/dg/order/addDgOrder', data, 'post', 'json').then(res => res);
    },
    //新增采购订单
    async bmg_addPurchaseOrder(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/addPurchaseOrder', data, 'post', 'json').then(res => res);
    },
    //作废采购订单
    async bmg_deletePurchaseOrder(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/deletePurchaseOrder', data, 'post', 'json').then(res => res);
    },
    //修改代购订单
    async bmg_updateDgOrder(data) {
        return await axios_requer('/admin/v1/dg/order/updateDgOrder', data, 'post', 'json').then(res => res);
    },
    //修改采购订单
    async bmg_updatePurchaseOrder(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/updatePurchaseOrder', data, 'post', 'json').then(res => res);
    },
    //获取代购订单详情
    async bmg_getDgOrderDetail(data) {
        return await axios_requer('/admin/v1/dg/order/getDgOrderDetail', data).then(res => res);
    },
    //获取采购订单详情
    async bmg_getPurchaseOrderDetail(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/getPurchaseOrderDetail', data).then(res => res);
    },
    //代购订单收款确认
    async bmg_collectionDgOrder(data) {
        return await axios_requer('/admin/v1/dg/order/collectionDgOrder', data, 'post', 'json').then(res => res);
    },
    //采购单付款
    async bmg_payPurchaseOrder(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/payPurchaseOrder', data, 'post', 'json').then(res => res);
    },
    //采购单退款
    async bmg_payRefundPurchaseOrder(data) {
        return await axios_requer('/dg/admin/v1/purchase/order/payRefundPurchaseOrder', data, 'post', 'json').then(res => res);
    },
    //会员下拉列表(有代理商的)
    async bmg_getMemberDownList(data) {
        return await axios_requer('admin/v1/dg/member/getMemberDownList', data).then(res => res);
    },
    //代购系统的会员列表(有邮箱之类的)
    async bmg_getMemberList(data) {
        return await axios_requer('/admin/v1/dg/member/getMemberList', data).then(res => res);
    },
    //获取专家购申请列表
    async bmg_getExpertBuyList(data) {
        return await axios_requer('/admin/v1/expert/buy/getExpertBuyList', data).then(res => res);
    },
    //获取专家购申请详情
    async bmg_getExpertBuyDetail(data) {
        return await axios_requer('/admin/v1/expert/buy/getExpertBuyDetail', data).then(res => res);
    },
    //获取专家购跟进记录
    async bmg_getExpertBuyRecord(data) {
        return await axios_requer('/admin/v1/expert/buy/getExpertBuyRecord', data).then(res => res);
    },
    //获取专家购跟进记录详情
    async bmg_getExpertBuyRecordDetail(data) {
        return await axios_requer('/admin/v1/expert/buy/getExpertBuyRecordDetail', data).then(res => res);
    },
    //新增专家购跟进记录
    async bmg_addExpertBuyRecord(data) {
        return await axios_requer('/admin/v1/expert/buy/addExpertBuyRecord', data, 'post', 'json').then(res => res);
    },
    //新增专家购跟进记录
    async bmg_updateExpertBuyRecord(data) {
        return await axios_requer('/admin/v1/expert/buy/updateExpertBuyRecord', data, 'post', 'json').then(res => res);
    },
    //删除专家购跟进记录
    async bmg_delExpertBuyRecord(data) {
        return await axios_requer('/admin/v1/expert/buy/delExpertBuyRecord', data, 'post', 'json').then(res => res);
    },
    //编辑专家购
    async bmg_updateExpertBuy(data) {
        return await axios_requer('/admin/v1/expert/buy/updateExpertBuy', data, 'post', 'json').then(res => res);
    },
    //新建专家购
    async bmg_addExpertBuy(data) {
        return await axios_requer('/admin/v1/expert/buy/addExpertBuy', data, 'post', 'json').then(res => res);
    },




    // 新版集运+代购

    // 预报包裹v2 (可以直接转运)
    async addPackageV2(data) {
        return await axios_requer('/v2/admin/package/addPackageV2', data, 'post', 'json').then(res => res);
    },
    // 合包列表
    async getBillClosePackageListV2(data) {
        return await axios_requer('/v2/admin/bill/close/package/getBillClosePackageListV2', data, 'post', 'json').then(res => res);
    },
    // 申请打包单选择列表v2(新建合包左边列表)
    async getPackPackagetSelectListV2(data) {
        return await axios_requer('/v2/admin/pack/package/getPackPackagetSelectListV2', data).then(res => res);
    },
    // 合包单的申请打包单列表v2(详情)
    async getBillCloseBillPackListV2(data) {
        return await axios_requer('/v2/admin/bill/close/package/getBillCloseBillPackListV2', data).then(res => res);
    },
    // 新建合包单V2
    async addBillClosePackageV2(data) {
        return await axios_requer('/v2/admin/bill/close/package/addBillClosePackageV2', data, 'post', 'json').then(res => res);
    },
    // 合包单明细列表v2
    async getBillClosePackageRecordListV2(data) {
        return await axios_requer('/v2/admin/bill/close/package/getBillClosePackageRecordListV2', data, 'post', 'json').then(res => res);
    },
    // 合包单入库
    async inStockV2(data) {
        return await axios_requer('/v2/admin/bill/close/package/inStockV2', data, 'post', 'json').then(res => res);
    },
    // 合包单出库
    async outStockV2(data) {
        return await axios_requer('/v2/admin/bill/close/package/outStockV2', data, 'post', 'json').then(res => res);
    },
    // 修改合包单V2
    async updateBillClosePackageV2(data) {
        return await axios_requer('/v2/admin/bill/close/package/updateBillClosePackageV2', data, 'post', 'json').then(res => res);
    },
    // 拆包
    async openClosePackage(data) {
        return await axios_requer('/v2/admin/bill/close/package/openClosePackage', data, 'post', 'json').then(res => res);
    },
    // 出入库记录列表v2(出入库日志)
    async getBillPackStockRecordV2(data) {
        return await axios_requer('/v2/admin/bill/pack/stock/record/getBillPackStockRecordV2', data, 'post', 'json').then(res => res);
    },
    // 申请打包列表(打包单综合查询)v2
    async getPackPackagetListV2(data) {
        return await axios_requer('/v2/admin/pack/package/getPackPackagetListV2', data, 'post', 'json').then(res => res);
    },
    // 打包单取消派件V2
    async cancelDispatchBillPackV2(data) {
        return await axios_requer('/v2/admin/pack/package/cancelDispatchBillPackV2', data, 'post', 'json').then(res => res);
    },
    // 补录转运单号V2
    async setBillChennelNumberV2(data) {
        return await axios_requer('/v2/admin/pack/package/setBillChennelNumberV2', data, 'post', 'json').then(res => res);
    },
    // 打包单出库
    async packPackageOutStockV2(data) {
        return await axios_requer('/v2/admin/pack/package/outStockV2', data, 'post', 'json').then(res => res);
    },
    // 打包单入库
    async packageInStockV2(data) {
        return await axios_requer('/v2/admin/pack/package/inStockV2', data, 'post', 'json').then(res => res);
    },
    // 派件确认
    async dispatchBillPackV2(data) {
        return await axios_requer('/v2/admin/pack/package/dispatchBillPackV2', data, 'post', 'json').then(res => res);
    },
    // 打包单详情
    async getPackPackagetDetailV2(data) {
        return await axios_requer('/v2/admin/pack/package/getPackPackagetDetailV2', data).then(res => res);
    },
    // 获取打包单操作日志v2
    async getBillPackOperateLogV2(data) {
        return await axios_requer('/v2/admin/pack/package/getBillPackOperateLogV2', data).then(res => res);
    },
    // 获取打包单财务应收详情
    async getBillPackFinanceInV2(data) {
        return await axios_requer('/v2/admin/pack/package/getBillPackFinanceInV2', data).then(res => res);
    },
    // 获取打包单快递列表v2
    async getBillPackExpressListV2(data) {
        return await axios_requer('/v2/admin/pack/package/getBillPackExpressListV2', data).then(res => res);
    },
    // 获取打包单附件列表v2
    async getBillPackFileListV2(data) {
        return await axios_requer('/v2/admin/pack/package/getBillPackFileListV2', data).then(res => res);
    },
    // 获取打包单出入库记录v2
    async getBillPackInOutStockRecordListV2(data) {
        return await axios_requer('/v2/admin/pack/package/getBillPackInOutStockRecordListV2', data).then(res => res);
    },
    // 获取打包明细的文件v2
    async getStorepackDescFileV2(data) {
        return await axios_requer('/v2/admin/pack/package/getStorepackDescFileV2', data).then(res => res);
    },
    // 添加会员(新)
    async dgMemberAddMember(data) {
        return await axios_requer('/admin/v1/dg/member/addMember', data, 'post', 'json').then(res => res);
    },
    // 修改会员(新)
    async updateMemberV2(data) {
        return await axios_requer('/admin/v1/dg/member/updateMemberV2', data, 'post', 'json').then(res => res);
    },
    // 会员详情(新)
    async memberDetailV2(data) {
        return await axios_requer('/admin/v1/dg/member/memberDetailV2', data).then(res => res);
    },
    //取消出库
    async cancelOutStockV2(data) {
        return await axios_requer('/v2/admin/pack/package/cancelOutStockV2', data, 'post', 'json').then(res => res);
    },
    //修改会员号
    async updateMemberIdV2(data) {
        return await axios_requer('/v1/admin/member/check/updateMemberIdV2', data, 'post').then(res => res);
    },
    //客服跟踪列表v2
    async getBillKfTrackRecordListV2(data) {
        return await axios_requer('/v2/admin/bill/kf/track/record/getBillKfTrackRecordListV2', data).then(res => res);
    },
    //新建客服跟踪V2
    async addBillKfTrackRecordV2(data) {
        return await axios_requer('/v2/admin/bill/kf/track/record/addBillKfTrackRecordV2', data, 'post', 'json').then(res => res);
    },
    //修改客服跟踪V2
    async updateBillKfTrackRecordV2(data) {
        return await axios_requer('/v2/admin/bill/kf/track/record/updateBillKfTrackRecordV2', data, 'post', 'json').then(res => res);
    },
    //删除客服跟踪V2
    async delBillKfTrackRecordV2(data) {
        return await axios_requer('/v2/admin/bill/kf/track/record/delBillKfTrackRecordV2', data, 'post', 'json').then(res => res);
    },
    //获取大货运单详情v2
    async getDaHuoBillPackDetailV2(data) {
        return await axios_requer('/v2/admin/pack/package/getDaHuoBillPackDetailV2', data).then(res => res);
    },
    //修改大货运单
    async updateDaHuoBillPackV2(data) {
        return await axios_requer('/v2/admin/pack/package/updateDaHuoBillPackV2', data, 'post', 'json').then(res => res);
    },
    //重置密码V2
    async resetPasswordV2(data) {
        return await axios_requer('/admin/v1/dg/member/resetPasswordV2', data, 'post').then(res => res);
    },
    //获取客服跟踪的文件v2
    async getBillKfTrackRecordFileV2(data) {
        return await axios_requer('/v2/admin/bill/kf/track/record/getBillKfTrackRecordFileV2', data).then(res => res);
    },
    //删除材积除
    async getdelDivisor(data) {
        return await axios_requer('/v1/admin/channel/delDivisor', data, 'post').then(res => res);
    },

    //上传运单附件
    async uploadWaybillFileV2(data) {
        return await axios_requer('/v2/admin/pack/package/uploadWaybillFileV2', data, 'post', 'json').then(res => res);
    },

    //启用费项
    async startItemService(data) {
        return await axios_requer('/v1/admin/expenditure/startItemService', data, 'post', 'json').then(res => res);
    },
    //禁用费项
    async stopItemService(data) {
        return await axios_requer('/v1/admin/expenditure/stopItemService', data, 'post', 'json').then(res => res);
    },
    //获取已知货币下拉列表
    async getHasCurrencyDownList(data) {
        return await axios_requer('/v1/admin/currency/getHasCurrencyDownList', data).then(res => res);
    },
    //获取租户类型
    async getTenantType(data) {
        return await axios_requer('/common/v1/tenant/getTenantType', data).then(res => res);
    },
    //运输轨迹
    async getBillTrack(data) {
        return await axios_requer('/v2/admin/pack/package/getBillTrack', data).then(res => res);
    },
    //新增关键字
    async addKeyword(data) {
        return await axios_requer('/admin/dg/v3/keyword/addKeyword', data, 'post').then(res => res);
    },
    //修改关键字
    async updateKeyword(data) {
        return await axios_requer('/admin/dg/v3/keyword/updateKeyword', data, 'post').then(res => res);
    },
    //删除关键字
    async delKeyword(data) {
        return await axios_requer('/admin/dg/v3/keyword/delKeyword', data, 'post').then(res => res);
    },
    //禁用关键字
    async stopKeyword(data) {
        return await axios_requer('/admin/dg/v3/keyword/stopKeyword', data, 'post').then(res => res);
    },
    //启用关键字
    async startKeyword(data) {
        return await axios_requer('/admin/dg/v3/keyword/startKeyword', data, 'post').then(res => res);
    },
    //获取关键字列表
    async getKeywordList(data) {
        return await axios_requer('/admin/dg/v3/keyword/getKeywordList', data).then(res => res);
    },


    // 字典类型列表(左边)
    async wtl_getConfDictTypeList(params) {
        return await axios_requer(
            "/admin/v3/tms/conf/getConfDictTypeList", params).then(res => res);
    },
    // 字典列表(右边)
    async wtl_getConfDictList(params) {
        return await axios_requer(
            "/admin/v3/tms/conf/getConfDictList", params).then(res => res);
    },
    // 新建字典
    async wtl_addConfDict(params) {
        return await axios_requer("/admin/v3/tms/conf/addConfDict", params, 'post').then(res => res);
    },
    // 修改字典
    async wtl_udpateConfDict(params) {
        return await axios_requer("/admin/v3/tms/conf/udpateConfDict", params, 'post').then(res => res);
    },
    // 删除字典
    async wtl_delConfDict(params) {
        return await axios_requer("/admin/v3/tms/conf/delConfDict", params, 'post', 'json').then(res => res);
    },
    // 启用字典
    async wtl_startConfDict(params) {
        return await axios_requer("/admin/v3/tms/conf/startConfDict", params, 'post', 'json').then(res => res);
    },
    // 禁用字典
    async wtl_stopConfDict(params) {
        return await axios_requer("/admin/v3/tms/conf/stopConfDict", params, 'post', 'json').then(res => res);
    },
    // 审核代购订单取消申请
    async examineCancelDgOrder(params) {
        return await axios_requer("/admin/v1/dg/order/examineCancelDgOrder", params, 'post', 'json').then(res => res);
    },
    // 添加异常V2
    async addExceptionV2(params) {
        return await axios_requer("/v1/admin/pack/exception/addExceptionV2", params, 'post', 'json').then(res => res);
    },
    // 新增商品分组
    async addGoodsGroup(params) {
        return await axios_requer("/admin/dg/v3/goods/addGoodsGroup", params, 'post').then(res => res);
    },
    // 修改商品分组
    async updateGoodsGroup(params) {
        return await axios_requer("/admin/dg/v3/goods/updateGoodsGroup", params, 'post').then(res => res);
    },
    // 获取商品分组列表
    async getGoodsGroupList(params) {
        return await axios_requer("/admin/dg/v3/goods/getGoodsGroupList", params).then(res => res);
    },
    // 添加商品
    async addGoods(params) {
        return await axios_requer("/admin/dg/v3/goods/addGoods", params, 'post', 'json').then(res => res);
    },
    // 修改商品
    async updateGoods(params) {
        return await axios_requer("/admin/dg/v3/goods/updateGoods", params, 'post', 'json').then(res => res);
    },
    // 删除商品
    async delGoods(params) {
        return await axios_requer("/admin/dg/v3/goods/delGoods", params, 'post', 'json').then(res => res);
    },
    // 获取商品列表
    async getGoodsList(params) {
        return await axios_requer("/admin/dg/v3/goods/getGoodsList", params).then(res => res);
    },
    // 获取商品详情
    async getGoodsDetail(params) {
        return await axios_requer("/admin/dg/v3/goods/getGoodsDetail", params).then(res => res);
    },
    // 上架商品
    async upShelfGoods(params) {
        return await axios_requer("/admin/dg/v3/goods/upShelfGoods", params, 'post', 'json').then(res => res);
    },
    // 下架商品
    async downShelfGoods(params) {
        return await axios_requer("/admin/dg/v3/goods/downShelfGoods", params, 'post', 'json').then(res => res);
    },
    // 删除商品分组
    async delGoodsGroup(params) {
        return await axios_requer("/admin/dg/v3/goods/delGoodsGroup", params, 'post', 'json').then(res => res);
    },
    // banner跳转类型列表
    async bannerUrlTypeList(params) {
        return await axios_requer("/v1/admin/news/bannerUrlTypeList", params).then(res => res);
    },
    // 获取左侧货物品类列表
    async getLeftItemCategoryList(params) {
        return await axios_requer("/v2/admin/channel/item/getLeftItemCategoryList", params).then(res => res);
    },
    // 获取渠道物品名称列表v2
    async getItemNameListV2(params) {
        return await axios_requer("/v2/admin/channel/item/getItemNameList", params).then(res => res);
    },
    // 获取物品类详情
    async getItemCategoryDetail(params) {
        return await axios_requer("/v2/admin/channel/item/getItemCategoryDetail", params).then(res => res);
    },
    // 添加渠道货物品类
    async addItemCategoryV2(params) {
        return await axios_requer("/v2/admin/channel/item/addItemCategory", params, 'post', 'json').then(res => res);
    },
    // 修改渠道货物品类
    async updateItemCategory(params) {
        return await axios_requer("/v2/admin/channel/item/updateItemCategory", params, 'post', 'json').then(res => res);
    },
    // 删除渠道货物品类
    async delItemCategory(params) {
        return await axios_requer("/v2/admin/channel/item/delItemCategory", params, 'post', 'json').then(res => res);
    },
    // 启用渠道货物品类
    async startItemCategory(params) {
        return await axios_requer("/v2/admin/channel/item/startItemCategory", params, 'post', 'json').then(res => res);
    },
    // 禁用渠道货物品类
    async stopItemCategory(params) {
        return await axios_requer("/v2/admin/channel/item/stopItemCategory", params, 'post', 'json').then(res => res);
    },
    // 添加品名V2
    async addItemName(params) {
        return await axios_requer("/v2/admin/channel/item/addItemName", params, 'post', 'json').then(res => res);
    },
    // 修改品名V2
    async updateItemName(params) {
        return await axios_requer("/v2/admin/channel/item/updateItemName", params, 'post', 'json').then(res => res);
    },
    // 启用品名
    async startItemName(params) {
        return await axios_requer("/v2/admin/channel/item/startItemName", params, 'post', 'json').then(res => res);
    },
    // 禁用品名
    async stopItemName(params) {
        return await axios_requer("/v2/admin/channel/item/stopItemName", params, 'post', 'json').then(res => res);
    },
    // 删除品名
    async delItemName(params) {
        return await axios_requer("/v2/admin/channel/item/delItemName", params, 'post', 'json').then(res => res);
    },
    // 修改异常V2
    async updateExceptionV2(params) {
        return await axios_requer("/v1/admin/pack/exception/updateExceptionV2", params, 'post', 'json').then(res => res);
    },
    // 获取渠道物品名称详情
    async getItemNameDetail(params) {
        return await axios_requer("/v2/admin/channel/item/getItemNameDetail", params).then(res => res);
    },
    // 异常详情V2
    async exceptionDetailV2(params) {
        return await axios_requer("/v1/admin/pack/exception/exceptionDetailV2", params).then(res => res);
    },
    // 异常处理详情V2
    async exceptionHandleDetailV2(params) {
        return await axios_requer("/v1/admin/pack/exception/exceptionHandleDetailV2", params).then(res => res);
    },
    // 异常处理V2
    async exceptionHandleV2(params) {
        return await axios_requer("/v1/admin/pack/exception/exceptionHandleV2", params, 'post', 'json').then(res => res);
    },
    // 分配客服
    async distributionKf(params) {
        return await axios_requer("/admin/v1/dg/order/distributionKf", params, 'post', 'json').then(res => res);
    },
    // 暂扣
    async withhold(params) {
        return await axios_requer("/v1/admin/pack/package/withhold", params, 'post', 'json').then(res => res);
    },
    // 取消暂扣
    async cancelWithhold(params) {
        return await axios_requer("/v1/admin/pack/package/cancelWithhold", params, 'post', 'json').then(res => res);
    },
    // 启用客服
    async startKfContact(params) {
        return await axios_requer("/v1/admin/kf/contact/startKfContact", params, 'post', 'json').then(res => res);
    },
    // 禁用客服
    async stopKfContact(params) {
        return await axios_requer("/v1/admin/kf/contact/stopKfContact", params, 'post', 'json').then(res => res);
    },
    // 客服详情
    async getKfContactDetail(params) {
        return await axios_requer("/v1/admin/kf/contact/getKfContactDetail", params).then(res => res);
    },
    // 更新主题颜色
    async saveTheme(params) {
        return await axios_requer("/admin/dg/v3/theme/saveTheme", params, 'post').then(res => res);
    },
    // 获取主题颜色
    async getThemeList(params) {
        return await axios_requer("/admin/dg/v3/theme/getThemeList", params).then(res => res);
    },
    // 执行动态查询sql(分页)
    async runPageReport(params) {
        return await axios_requer("/v1/common/query/runPageReport", params, 'post', 'json').then(res => res);
    },
    // 执行动态查询sql(不分页)
    async runReport(params) {
        return await axios_requer("/v1/common/query/runReport", params, 'post', 'json').then(res => res);
    },
    // 执行动态修改
    async runUpdateSql(params) {
        return await axios_requer("/v1/common/query/runUpdateSql", params, 'post', 'json').then(res => res);
    },
    // 获取动态查询展示字段
    async getReportFiledsV2(params) {
        return await axios_requer("/v1/common/query/getReportFiledsV2", params, 'post', 'json').then(res => res);
    },

    // 根据字典类型获取字典值下拉列表
    async getConfDictValDownList(params) {
        return await axios_requer("/member/v4/confdict/getConfDictValDownList", params).then(res => res);
        // return await axios_requer("/admin/v3/tms/conf/getConfDictValDownList", params).then(res => res);
    },


    // 获取行事历列表
    async getCalentdarActionList(params) {
        return await axios_requer("/admin/v4/action/calentdar/getCalentdarActionList", params).then(res => res);
    },
    // 新增行事历
    async addCalentdarAction(params) {
        return await axios_requer("/admin/v4/action/calentdar/addCalentdarAction", params, 'post', 'json').then(res => res);
    },
    // 修改行事历
    async updateCalentdarAction(params) {
        return await axios_requer("/admin/v4/action/calentdar/updateCalentdarAction", params, 'post', 'json').then(res => res);
    },
    // 删除行事历
    async delCalentdarAction(params) {
        return await axios_requer("/admin/v4/action/calentdar/delCalentdarAction", params, 'post', 'json').then(res => res);
    },
    // 启用行事历
    async startCalentdarAction(params) {
        return await axios_requer("/admin/v4/action/calentdar/startCalentdarAction", params, 'post', 'json').then(res => res);
    },
    // 禁用行事历
    async stopCalentdarAction(params) {
        return await axios_requer("/admin/v4/action/calentdar/stopCalentdarAction", params, 'post', 'json').then(res => res);
    },
    // 获取行事历详情
    async getCalentdarActionDetail(params) {
        return await axios_requer("/admin/v4/action/calentdar/getCalentdarActionDetail", params).then(res => res);
    },
    // 获取行事历时间列表
    async getAddFields(params) {
        return await axios_requer("/admin/v4/action/calentdar/date/getAddFields", params).then(res => res);
    },
    // 导入行事历时间
    async importDates(params) {
        return await axios_requer("/admin/v4/action/calentdar/date/importDates", params, 'post', 'json').then(res => res);
    },
    // 新增-修改行事历时间
    async addNodeDate(params) {
        return await axios_requer("/admin/v4/action/calentdar/date/addNodeDate", params, 'post', 'json').then(res => res);
    },
    // 删除行事历时间
    async delNodeDate(params) {
        return await axios_requer("/admin/v4/action/calentdar/date/delNodeDate", params, 'post', 'json').then(res => res);
    },
    // 获取商超地址
    async storeselectstore(params) {
        return await axios_requer("/v4/common/lvjie/store/select/store", params).then(res => res);
    },
    // 获取商超地址
    async storeselectstoreresult(params) {
        return await axios_requer("/v4/common/lvjie/store/select/store/result", params).then(res => res);
    },
    // 获取行事历时间详情
    async getNodeDateDetail(params) {
        return await axios_requer("/admin/v4/action/calentdar/date/getNodeDateDetail", params, 'post', 'json').then(res => res);
    },


    // 删除快递公司
    async delExpress(params) {
        return await axios_requer("/v1/admin/express/delExpress", params, 'post', 'json').then(res => res);
    },



    // 添加-修改渠道v4
    async addOrUpdateChannelV4(params) {
        return await axios_requer("/v1/admin/channel/addOrUpdateChannelV4", params, 'post', 'json').then(res => res);
    },
    //获取渠道仓库信息v4
    async getChannelStorageInfoV4(data) {
        return await axios_requer('/v1/admin/channel/getChannelStorageInfoV4', data, 'GET').then(res => res);
    },


    // 添加-修改渠道报价V4
    async addOrUpdateOfferV4(params) {
        return await axios_requer("/v1/admin/channel/addOrUpdateOfferV4", params, 'post', 'json').then(res => res);
    },

    //获取渠道报价仓库信息v4
    async getChannelOfferStorageInfoV4(data) {
        return await axios_requer('/v1/admin/channel/getChannelOfferStorageInfoV4', data, 'GET').then(res => res);
    },

    //新建优惠券
    async addCoupon(params) {
        return await axios_requer('/v3/admin/coupon/addCoupon', params, 'post', 'json').then(res => res);
    },
    //删除优惠券
    async delCoupon(params) {
        return await axios_requer('/v3/admin/coupon/delCoupon', params, 'post', 'json').then(res => res);
    },
    //删除领取的优惠券
    async delGetCoupon(params) {
        return await axios_requer('/v3/admin/coupon/delGetCoupon', params, 'post', 'json').then(res => res);
    },
    //下架优惠券
    async downCoupon(params) {
        return await axios_requer('/v3/admin/coupon/downCoupon', params, 'post', 'json').then(res => res);
    },
    //上架优惠券
    async upCoupon(params) {
        return await axios_requer('/v3/admin/coupon/upCoupon', params, 'post', 'json').then(res => res);
    },
    //手动发放优惠券
    async sendCoupon(params) {
        return await axios_requer('/v3/admin/coupon/sendCoupon', params, 'post', 'json').then(res => res);
    },
    //修改优惠券
    async updateCoupon(params) {
        return await axios_requer('/v3/admin/coupon/updateCoupon', params, 'post', 'json').then(res => res);
    },


    // 动态接口
    // 获取动态查询列表
    async getQueryList(data) {
        return await axios_requer("/v1/common/query/getQueryList",
            data,
            'GET',
        ).then((res) => res);
    },
    // 获取动态查询条件
    async getReportConditions(data) {
        return await axios_requer("/v1/common/query/getReportConditions",
            data,
            'GET',
        ).then((res) => res);
    },
    // 获取动态查询展示字段
    async getReportFileds(data) {
        return await axios_requer("/v1/common/query/getReportFileds",
            data,
            'GET',
        ).then((res) => res);
    },
    // 获取动态查询展示字段
    async getReportFiledsV2(params) {
        return await axios_requer("/v1/common/query/getReportFiledsV2",
            params, 'post', 'json',
        ).then((res) => res);
    },
    // 执行动态查询sql(分页)
    async runPageReport(params) {
        return await axios_requer("/v1/common/query/runPageReport",
            params, 'post', 'json',
        ).then((res) => res);
    },
    // 执行动态查询sql(不分页) 详情
    async runPageReportDetails(params) {
        return await axios_requer("/v1/common/query/runReport",
            params, 'post', 'json',
        ).then((res) => res);
    },




}




export {
    apiList,
    Api
};