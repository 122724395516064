// import tools from './tool'

let loginOpen = false //true==申请试用 ,false==正常登录(八米跨境) 
let prefix = 'pc_prefix'; //短信前缀
let appid = 'hy7vptfjxs6zhwsowz';
let appsecret = '1f29443847dabd29ef06c319f21fcecf';

let domain = '';
let baseURL = '';
let serve_s = 'build' //dev==开发环境 ,build==发布打包

if (process.env.NODE_ENV === "development") {
    // 开发环境
    serve_s = 'dev'
} else {
    serve_s = 'build'
    // alert("生产环境");
}
// 注意:如果是代购的,要把域名加到tool.js 的 isDaiGou()里面的数组
// baseURL = 'http://192.168.1.37:8080'; // 
// baseURL = 'http://192.168.1.17:8080'; //
// baseURL = 'https://dev.b9.api.8bami.com'; //测试服务器
// baseURL = 'https://dev.gjtms.common.8bami.com'; //测试服务器

// 正式 
baseURL = 'https://b9.api.8bami.com'; //正式服务器
// bamibuytest 代购
// huayuan1001 集运 
// domain = 'dev.admin.bamibuy.com'  //八米购测试
// domain = 'dev.b9.admin.8bami.com' //鑫华远(集运)
// domain = 'live.admin.bamibuy.com' //八米购 正式
// domain = 'xhy.dg.admin.8bami.com' //鑫华远(集运+代购)
// domain = 'bamibuy.admin.8bami.com' //八米购(弃用)
// domain = 'hoomoo.admin.8bami.com' //货猫
// domain = 'b9.admin.xhyitsoft.com' //八米跨境 
// domain = 'admin.ywhhwl.com' //中坦国际
domain = 'gzjiejia.admin.8bami.com' //捷佳  如果更新捷佳的需要把这个放开


//中坦
// if (domain == 'admin.ywhhwl.com') {
//     baseURL = "http://ztlogin.ywhhwl.com"
//     appid = "ztiojpy3tk66uz6tuw"
//     appsecret = "11740557a9a3bb1e27510c446479e121"
// }

// 企业id
// t03  八米正式

let uploadUrl = baseURL + '/base/saas/v1/file/upload'; //文件上传接口


// const appid = 'c96ecuusfzpwbazoaf'
// const appsecret = '9e4a19ef270b9c21f551bd281533297b'

let pageTotals = 100;//每页条数

export default {
    baseURL,
    appid,
    appsecret,
    uploadUrl,
    prefix,
    serve_s,
    domain,
    loginOpen,
    pageTotals,
}