import Vue from "vue";
import VueRouter from "vue-router";
import router from "./router";
import ElementUI from "element-ui";
// import { Message } from 'element-ui';
import "@/assets/js/http_axios";
import "element-ui/lib/theme-chalk/index.css";
import common from "@/assets/js/comm.js";
import App from "./App.vue";
import "default-passive-events";
import Print from "@/assets/js/print.js";
import Tools from "@/assets/js/tool";
import comAPI from "@/assets/js/comAPI.js";
import { Api } from "@/assets/js/api";
import { showLoading, hideLoading } from "@/assets/js/loading";

import VueQuillEditor from "vue-quill-editor";

// 引入样式
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

Vue.use(VueQuillEditor);

ElementUI.Dialog.props.closeOnClickModal.default = false;
Vue.use(VueRouter);
Vue.use(ElementUI);
Vue.use(Print);

function hasBtnPermission(permission) {
  let PermissionArr = JSON.parse(sessionStorage.getItem("btnPermiss")) || [];
  return PermissionArr.indexOf(permission) > -1;
}
Vue.prototype.hasPermiss = hasBtnPermission;
Vue.prototype.common = common;
Vue.prototype.Tools = Tools;
Vue.prototype.$comAPI = comAPI;
Vue.prototype.$API = Api;
Vue.prototype.$showLoading = showLoading;
Vue.prototype.$hideLoading = hideLoading;

// 注意：这里使用的$message,所以在使用时候也是this.$message
Vue.prototype.$message = function(msg) {
  return ElementUI.Message({
    message: msg,
    duration: 1800,
    showClose: true,
  });
};
//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function(msg) {
  return ElementUI.Message.success({
    message: msg,
    duration: 1800,
    showClose: true,
  });
};
Vue.prototype.$message.warning = function(msg) {
  return ElementUI.Message.warning({
    message: msg,
    duration: 2000,
    showClose: true,
  });
};
Vue.prototype.$message.error = function(msg) {
  return ElementUI.Message.error({
    message: msg,
    duration: 2000,
    showClose: true,
  });
};

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
}).$mount("#app");
Vue.prototype.$keyBoard = function(vm, methodName, code) {
  document.onkeydown = function() {
    let key = window.event.keyCode;
    if (key == code) {
      vm[methodName](code); // 触发methodName事件
    }
  };
};
